import { Card, Grid, Group, SimpleGrid, Stack, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Event } from '@/types/event'
import { getFormattedDateWithTime } from '@/utils/date'
import { EventRecordings } from '../EventRecordings/EventRecordings'
import { EventInfoCard } from './EventInfoCard/EventInfoCard'
import { extractRoiNames } from './extractRoiNames'

type LabeledValueProps = {
  label: string | ReactNode
  value: string
}

const LabeledValue = ({ label, value }: LabeledValueProps) => {
  return (
    <Group wrap="nowrap">
      <Text c="dimmed" size="sm" fw={600}>
        {label}
      </Text>

      <Text size="sm">{value}</Text>
    </Group>
  )
}

type EventDetailLeftPaneProps = {
  event: Event
}

export const EventDetailLeftPane = ({ event }: EventDetailLeftPaneProps) => {
  const roiNames = extractRoiNames(event)

  return (
    <Grid.Col span={{ base: 12, md: 7, lg: 7 }}>
      <Stack>
        {event.recordings.length > 0 && (
          <EventRecordings eventId={event.id} recordings={event.recordings} />
        )}

        <EventInfoCard title={<FormattedMessage id="events.details" />}>
          <Card.Section py="lg" inheritPadding>
            <SimpleGrid cols={{ base: 1, lg: 2 }} spacing="lg">
              <LabeledValue
                label={<FormattedMessage id="events.details.id" />}
                value={event.id}
              />

              <LabeledValue
                label={<FormattedMessage id="events.details.capturedOn" />}
                value={getFormattedDateWithTime(event.timestamp.$date)}
              />

              <LabeledValue
                label={<FormattedMessage id="events.details.device" />}
                value={event.metadata.device?.label || ''}
              />

              <LabeledValue
                label={<FormattedMessage id="events.details.stream" />}
                value={event.metadata.camera?.name || ''}
              />

              <LabeledValue
                label={<FormattedMessage id="events.details.model" />}
                value={event.metadata.model_name || ''}
              />

              <LabeledValue
                label={<FormattedMessage id="events.details.businessLogic" />}
                value={roiNames}
              />
            </SimpleGrid>
          </Card.Section>
        </EventInfoCard>

        {event.metadata.note && (
          <EventInfoCard title={<FormattedMessage id="events.notes" />}>
            <Card.Section py="lg" inheritPadding>
              <Text
                size="sm"
                style={{
                  whiteSpace: 'pre-line'
                }}
              >
                {event.metadata.note}
              </Text>
            </Card.Section>
          </EventInfoCard>
        )}
      </Stack>
    </Grid.Col>
  )
}
