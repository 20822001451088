import { KonvaEventObject } from 'konva/lib/Node'
import { useEffect, useState } from 'react'
import { Arrow, Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'
import { useDraggablePoint } from '../useDraggablePoint'

const CIRCLE_SIZE = 4

type LineCrossingProps = {
  variant: 'line' | 'direction'
  name: string
  color: string
  initialPoints: number[][]
  onCoordinateChange: (points: number[][]) => void
}

export const LineCrossing = ({
  variant,
  name,
  color,
  initialPoints,
  onCoordinateChange
}: LineCrossingProps) => {
  const [points, setPoints] = useState<number[][]>([
    [0, 0],
    [0, 0]
  ])

  useEffect(() => {
    setPoints(initialPoints)
  }, [initialPoints])

  const handlePointDragMove = (
    e: KonvaEventObject<DragEvent>,
    pointIndex: number
  ) => {
    setPoints([
      ...points.slice(0, pointIndex),
      [e.target.x() + 1, e.target.y() + 1],
      ...points.slice(pointIndex + 1)
    ])
  }

  const { handlePointMouseOver, handlePointMouseOut, pointDragBoundFunc } =
    useDraggablePoint()

  return (
    <Group>
      {variant === 'line' ? (
        <Line
          points={[points[0], points[1]].flat()}
          stroke={color}
          strokeWidth={3}
          lineJoin="round"
          dash={[20, 5]}
        />
      ) : (
        <Arrow
          points={[points[0], points[1]].flat()}
          pointerWidth={16}
          pointerLength={16}
          fill={color}
          stroke={color}
          strokeWidth={3}
        />
      )}

      {points.map((point, index) => {
        const x = point[0] - 1
        const y = point[1] - 1

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill="white"
            stroke={color}
            strokeWidth={3}
            dragBoundFunc={(pos) => pointDragBoundFunc(pos, CIRCLE_SIZE)}
            draggable
            onDragMove={(e) => handlePointDragMove(e, index)}
            onDragEnd={() => onCoordinateChange(points)}
            onMouseOver={handlePointMouseOver}
            onMouseOut={handlePointMouseOut}
          />
        )
      })}

      <AnnotationLabel
        x={points[0][0] - 10}
        y={points[0][1] + 10}
        color="rgba(0,0,0,0.6)"
        textColor="white"
        text={variant === 'line' ? name : `${name} - direction`}
      />
    </Group>
  )
}
