import * as Sentry from '@sentry/react'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { UserSubscription } from '@/types/subscription'
import { User } from '@/types/user'
import { getWhoAmIandMySubscription } from './helpers'

type ContextProps = {
  isAuthenticated: boolean
  hasSubscription: boolean
  hasWorkspace: boolean
  user: User | null
  userSubscription: UserSubscription | null
  subscriptionAddons: UserSubscription[]
  setUser: (user: User | null) => void
  setSubscriptions: (subscriptions: UserSubscription[]) => void
  clearSession: () => void
}

const initialValues = {
  isAuthenticated: false,
  hasSubscription: false,
  hasWorkspace: false,
  user: null,
  userSubscription: null,
  subscriptionAddons: [],
  setUser: () => {},
  setSubscriptions: () => {},
  clearSession: () => {}
}

const SessionContext = createContext<ContextProps>(initialValues)

type SessionProviderProps = {
  children: ReactNode
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [subscriptions, setSubscriptions] = useState<UserSubscription[]>([])
  const [isReady, setIsReady] = useState(false)

  const userSubscription =
    subscriptions.filter(
      (subscription) =>
        subscription.product_metadata.product_type === 'subscription_plan'
    )[0] || null

  const subscriptionAddons = subscriptions.filter(
    (subscription) =>
      subscription.product_metadata.product_type === 'plan_addon'
  )

  const clearSession = () => {
    setUser(null)
    setSubscriptions([])
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { user, subscriptions } = await getWhoAmIandMySubscription()
        setUser(user)

        Sentry.setUser({
          id: user.id
        })

        setSubscriptions(subscriptions)
      } catch {
        clearSession()
      }

      setIsReady(true)
    }

    if (!user) {
      void fetchUser()
    }
  }, [user])

  const isAuthenticated = Boolean(user)
  const hasSubscription = Boolean(userSubscription)
  const hasWorkspace = Boolean(user?.workspace)

  return (
    <SessionContext.Provider
      value={{
        user,
        userSubscription,
        subscriptionAddons,
        isAuthenticated,
        hasSubscription,
        hasWorkspace,
        setUser,
        setSubscriptions,
        clearSession
      }}
    >
      {isReady ? children : null}
    </SessionContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useSession = () => {
  return useContext(SessionContext)
}
