import { Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useGetDatasetVersionsLazy,
  useInvalidateDatasetList
} from '@/queries/datasetQueries'
import {
  useInvalidateModelDetails,
  useUpdateModel
} from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { Dataset, DatasetType } from '@/types/dataset'
import { MLModelType } from '@/types/model'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { useModelStepNavigation } from '../models/ModelDetails/useModelStepNavigation'
import { CreateDatasetHandler } from './CreateDatasetHandler/CreateDatasetHandler'
import { CreateDatasetModal } from './CreateDatasetModal/CreateDatasetModal'
import { OwnDatasetList } from './DatasetList/OwnDatasetList'
import { DatasetListToolbar } from './DatasetListToolbar/DatasetListToolbar'
import { DatasetPreview } from './DatasetPreview/DatasetPreview'
import { DeleteDatasetModal } from './DeleteDatasetModal/DeleteDatasetModal'
import { ImageCropHandler } from './ImageCrop/ImageCropHandler'
import { ImageCropModal } from './ImageCrop/ImageCropModal'

export const SelectDatasetPage = () => {
  const intl = useIntl()

  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null)
  const [previewDataset, setPreviewDataset] = useState<Dataset | null>(null)
  const [datasetToRemove, setDatasetToRemove] = useState<Dataset | null>(null)
  const [datasetToCrop, setDatasetToCrop] = useState<Dataset | null>(null)

  const [isPending, setIsPending] = useState(false)

  const [
    isCreateModalOpened,
    { open: openCreateModal, close: closeCreateModal }
  ] = useDisclosure(false)

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { goToLabelDataScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { getDatasetVersionsLazy } = useGetDatasetVersionsLazy()

  const { mutateAsync: updateModel } = useUpdateModel(model?.id || '')

  const { invalidateDatasetList } = useInvalidateDatasetList()
  const { invalidateModelDetails } = useInvalidateModelDetails(model?.id || '')

  const initialDatasetId = model?.dataset_version?.dataset || ''

  const assignDatasetAndNavigate = async (datasetId: string) => {
    setIsPending(true)

    try {
      const data = await getDatasetVersionsLazy(datasetId)
      const latestVersion = data?.pages[0]?.results[0].id

      await updateModel({
        modelId: model?.id || '',
        data: {
          dataset_version_id: latestVersion
        }
      })

      goToLabelDataScreen()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.assign.error' })

      showToast(message, 'error')
    }

    setIsPending(false)
  }

  const handleOnContinue = () => {
    void assignDatasetAndNavigate(selectedDataset?.id || '')
  }

  const handleDatasetCreated = (newDataset: Dataset) => {
    closeCreateModal()
    invalidateDatasetList()

    if (newDataset.dataset_type === DatasetType.Primary) {
      void assignDatasetAndNavigate(newDataset.id)
    } else {
      setDatasetToCrop(newDataset)
    }
  }

  const handleDatasetDeleted = () => {
    if (selectedDataset?.id === datasetToRemove?.id) {
      setSelectedDataset(null)
    }

    setDatasetToRemove(null)
    invalidateDatasetList()
    invalidateModelDetails()
  }

  const handleCropSuccess = async () => {
    await assignDatasetAndNavigate(datasetToCrop?.id || '')
    setDatasetToCrop(null)
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <DatasetListToolbar
          isDatasetSelected={!!selectedDataset}
          name={selectedDataset?.name || ''}
          isPending={isPending}
          onContinue={handleOnContinue}
        />
      }
    >
      <OwnDatasetList
        initialDatasetId={initialDatasetId}
        selectedDatasetId={selectedDataset?.id || ''}
        datasetType={
          model?.model_type === MLModelType.Primary
            ? DatasetType.Primary
            : DatasetType.Secondary
        }
        onCreateDataset={openCreateModal}
        onPreviewDataset={setPreviewDataset}
        onSelectDataset={setSelectedDataset}
        onResetSelection={() => setSelectedDataset(null)}
        onDeleteDataset={setDatasetToRemove}
      />

      <CreateDatasetModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
      >
        <Box mt="md">
          <CreateDatasetHandler
            datasetType={
              model?.model_type === MLModelType.Primary
                ? DatasetType.Primary
                : DatasetType.Secondary
            }
            onCancel={closeCreateModal}
            onDatasetCreated={handleDatasetCreated}
          />
        </Box>
      </CreateDatasetModal>

      <DatasetPreview
        datasetId={previewDataset?.id || ''}
        opened={!!previewDataset}
        onClose={() => setPreviewDataset(null)}
      />

      <DeleteDatasetModal
        opened={datasetToRemove !== null}
        datasetId={datasetToRemove?.id || ''}
        onDelete={handleDatasetDeleted}
        onClose={() => setDatasetToRemove(null)}
      />

      <ImageCropModal
        opened={datasetToCrop !== null}
        onClose={() => setDatasetToCrop(null)}
      >
        {datasetToCrop && (
          <ImageCropHandler
            targetDataset={datasetToCrop}
            onSkip={() => void handleCropSuccess()}
            onCropSuccess={() => void handleCropSuccess()}
          />
        )}
      </ImageCropModal>
    </PageWithFixedToolbar>
  )
}
