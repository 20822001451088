import { Button, Group, ThemeIcon, Title, Tooltip } from '@mantine/core'
import { IconBolt } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type ModelListToolbarProps = {
  isMultiStage?: boolean
  isDeployDisabled?: boolean
  isLoading?: boolean
  onDeploy: () => void
}

export const ModelListToolbar = ({
  isMultiStage,
  isDeployDisabled,
  isLoading,
  onDeploy
}: ModelListToolbarProps) => {
  return (
    <Group h="100%" align="center" justify="space-between">
      <Group wrap="nowrap" gap="xs">
        <ThemeIcon variant="transparent" size="md">
          <IconBolt style={{ width: '90%', height: '90%' }} />
        </ThemeIcon>

        <Title order={3}>
          <FormattedMessage id="models.myModels" />
        </Title>
      </Group>

      <Tooltip
        w={240}
        position="bottom"
        disabled={!isDeployDisabled}
        label={
          isMultiStage ? (
            <FormattedMessage id="models.list.deployHint.multiStage" />
          ) : (
            <FormattedMessage id="models.list.deployHint.singleStage" />
          )
        }
        multiline
      >
        <Button
          color="green"
          miw={160}
          loading={isLoading}
          disabled={isDeployDisabled}
          onClick={onDeploy}
        >
          <FormattedMessage
            id="deployments.deployModel"
            values={{
              count: isMultiStage ? 2 : 1
            }}
          />
        </Button>
      </Tooltip>
    </Group>
  )
}
