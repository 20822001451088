import { Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateDataset } from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { Dataset, DatasetType } from '@/types/dataset'
import { DatasetForm, FormValues } from '../DatasetForm/DatasetForm'

type CreateDatasetHandlerProps = {
  datasetType: DatasetType
  onCancel: () => void
  onDatasetCreated: (dataset: Dataset) => void
}

export const CreateDatasetHandler = ({
  datasetType,
  onCancel,
  onDatasetCreated
}: CreateDatasetHandlerProps) => {
  const intl = useIntl()

  const { mutateAsync: createDataset, isPending } = useCreateDataset()

  const handleCreateDataset = async (values: FormValues) => {
    const { name, description, classes, labelingType } = values

    try {
      const dataset = await createDataset({
        name,
        description,
        dataset_type: datasetType,
        labels: classes.map((c) => ({ name: c.name, color: c.color })),
        labeling_type: labelingType,
        is_library: false
      })

      onDatasetCreated(dataset)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.create.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isPending} />

      <DatasetForm
        submitLabel={<FormattedMessage id="datasets.create" />}
        onCancel={onCancel}
        onSubmit={(values) => void handleCreateDataset(values)}
      />
    </Box>
  )
}
