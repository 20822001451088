import { Box, Button, Card, Group, Stack, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApplicationType } from '@/types/app'
import { AppTypeSelector } from './AppTypeSelector'
import { WorkflowHelpSection } from './WorkflowHelpSection'

export type FormValues = {
  appName: string
  appType: ApplicationType
}

type CreateApplicationFormProps = {
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
  onChatbotClick: () => void
}

export const CreateApplicationForm = ({
  onCancel,
  onSubmit,
  onChatbotClick
}: CreateApplicationFormProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      appName: '',
      appType: ApplicationType.SingleStage
    },

    validate: {
      appName: isNotEmpty(
        intl.formatMessage({ id: 'apps.create.enterAppName' })
      )
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack gap={48}>
        <TextInput
          label={intl.formatMessage({ id: 'apps.appName' })}
          autoFocus
          data-autofocus // required for auto focus inside the modal
          {...form.getInputProps('appName')}
        />

        <Box>
          <AppTypeSelector
            selectedType={form.values.appType}
            onChange={(appType) => form.setFieldValue('appType', appType)}
          />

          <Card bg="blue.0" mt="sm" padding="lg">
            <WorkflowHelpSection onChatbotClick={onChatbotClick} />
          </Card>
        </Box>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button type="submit">
            <FormattedMessage id="apps.create" />
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
