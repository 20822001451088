import { Button, Group, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'

export type FormValues = {
  appName: string
}

type UpdateApplicationFormProps = {
  appName: string
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

const messages = {
  appName: {
    id: 'apps.appName'
  },
  enterAppName: {
    id: 'apps.create.enterAppName'
  }
}

export const UpdateApplicationForm = ({
  appName,
  onCancel,
  onSubmit
}: UpdateApplicationFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      appName
    },

    validate: {
      appName: isNotEmpty(intl.formatMessage(messages.enterAppName))
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <TextInput
        label={intl.formatMessage(messages.appName)}
        mb="xl"
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('appName')}
      />

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit">
          <FormattedMessage id="apps.updateApp" />
        </Button>
      </Group>
    </form>
  )
}
