import {
  ComboboxItem,
  Group,
  Select,
  SelectProps,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import { DatasetVersion, DatasetVersionStatus } from '@/types/dataset'
import { DatasetStatusChip } from '../DatasetStatusChip/DatasetStatusChip'

type SelectOptionProps = {
  name: string
  status: DatasetVersionStatus
  isChecked: boolean
}

const SelectOption = ({ name, status, isChecked }: SelectOptionProps) => {
  return (
    <Group flex="1" align="start" wrap="nowrap" py={4}>
      <Group wrap="nowrap" gap="xs" align="center">
        <Text size="sm">{name}</Text>
        <DatasetStatusChip status={status} />
      </Group>

      {isChecked && (
        <ThemeIcon
          color="green"
          size="xs"
          style={{ marginInlineStart: 'auto' }}
        >
          <IconCheck style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      )}
    </Group>
  )
}

type DatasetVersionSelectProps = SelectProps & {
  versions: DatasetVersion[]
}

export const DatasetVersionSelect = (props: DatasetVersionSelectProps) => {
  const { versions, ...selectProps } = props
  const intl = useIntl()

  const versionOptions = versions.map((version) => ({
    value: version.id,
    label: intl
      .formatMessage(
        { id: 'datasets.versionNumber' },
        { number: version.version }
      )
      .toLowerCase(),
    version
  }))

  return (
    <Select
      placeholder={intl.formatMessage({
        id: 'datasets.version.select'
      })}
      data={versionOptions}
      allowDeselect={false}
      renderOption={({ option, checked }) => {
        const { version } = option as ComboboxItem & { version: DatasetVersion }

        return (
          <SelectOption
            name={option.label}
            status={version.status}
            isChecked={checked || false}
          />
        )
      }}
      {...selectProps}
    />
  )
}
