import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useStartDeployment } from '@/queries/deploymentQueries'
import { showToast } from '@/theme/notifications'

type StartDeploymentConfirmationProps = {
  opened: boolean
  deploymentId: string
  onClose: () => void
  onStartTriggered: () => void
}

export const StartDeploymentConfirmation = ({
  opened,
  deploymentId,
  onClose,
  onStartTriggered
}: StartDeploymentConfirmationProps) => {
  const intl = useIntl()
  const { mutateAsync: startDeployment, isPending } = useStartDeployment()

  const handleStartDeployment = async () => {
    try {
      await startDeployment(deploymentId)
      onStartTriggered()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'deployments.startApp.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="deployments.startApp" />}
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage id="deployments.startApp.confirmation" />
        </Text>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button onClick={() => void handleStartDeployment()}>
            <FormattedMessage id="deployments.startApp" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
