import { SimpleGrid, Stack, Title, Tooltip } from '@mantine/core'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetDatasets } from '@/queries/datasetQueries'
import { Dataset, DatasetType } from '@/types/dataset'
import { AddButtonCard } from '../../ui-shared/AddButtonCard/AddButtonCard'
import { InfiniteScroll } from '../../ui-shared/InfiniteScroll/InfiniteScroll'
import { DatasetCard } from '../DatasetCard/DatasetCard'

type OwnDatasetListProps = {
  initialDatasetId?: string
  selectedDatasetId: string
  datasetType: DatasetType
  onCreateDataset: () => void
  onPreviewDataset: (dataset: Dataset) => void
  onSelectDataset: (dataset: Dataset) => void
  onDeleteDataset: (dataset: Dataset) => void
  onResetSelection: () => void
}

export const OwnDatasetList = ({
  initialDatasetId,
  selectedDatasetId,
  datasetType,
  onCreateDataset,
  onPreviewDataset,
  onSelectDataset,
  onDeleteDataset,
  onResetSelection
}: OwnDatasetListProps) => {
  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetDatasets({
      is_library: false,
      ordering: 'created_at'
    })

  const datasets = data?.pages?.flatMap((page) => page.results) || []

  const initialDataset = datasets.find(
    (dataset) => dataset.id === initialDatasetId
  )

  useEffect(() => {
    if (initialDataset) {
      onSelectDataset(initialDataset)
    }
  }, [initialDataset, onSelectDataset])

  const toggleSelection = (dataset: Dataset) => {
    if (selectedDatasetId === dataset.id) {
      onResetSelection()
    } else {
      onSelectDataset(dataset)
    }
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.list.error" />
      </ErrorWithReload>
    )
  }

  return (
    <Stack mt={10}>
      <Title order={4}>
        <FormattedMessage id="datasets.own" />
      </Title>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={() => void fetchNextPage()}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
          <AddButtonCard
            height={175}
            label={<FormattedMessage id="datasets.add" />}
            onClick={onCreateDataset}
          />

          {datasets.map((dataset) => (
            <Tooltip
              key={dataset.id}
              label={
                datasetType === DatasetType.Primary ? (
                  <FormattedMessage id="datasets.secondary.disabledHint" />
                ) : (
                  <FormattedMessage id="datasets.primary.disabledHint" />
                )
              }
              disabled={dataset.dataset_type === datasetType}
            >
              <div>
                <DatasetCard
                  name={dataset.name}
                  fileCount={dataset.media_files_count}
                  datasetType={dataset.dataset_type}
                  isSelected={selectedDatasetId === dataset.id}
                  disabled={dataset.dataset_type !== datasetType}
                  thumbnailUrl={dataset.thumbnail || undefined}
                  onDelete={() => onDeleteDataset(dataset)}
                  onPreview={() => onPreviewDataset(dataset)}
                  onSelect={() => toggleSelection(dataset)}
                />
              </div>
            </Tooltip>
          ))}
        </SimpleGrid>
      </InfiniteScroll>
    </Stack>
  )
}
