import { FormattedMessage } from 'react-intl'
import { LibraryLogicType } from '@/types/businessLogic'

const nameMap = {
  [LibraryLogicType.ObjectDetection]: 'logic.objectDetection',
  [LibraryLogicType.ObjectCount]: 'logic.objectCount'
}

type BusinessLogicNameProps = {
  businessLogicType: LibraryLogicType
}

export const BusinessLogicName = ({
  businessLogicType
}: BusinessLogicNameProps) => {
  return <FormattedMessage id={nameMap[businessLogicType]} />
}
