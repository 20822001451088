import {
  Button,
  Card,
  Code,
  Group,
  List,
  Select,
  Stack,
  Text,
  TextInput,
  ThemeIcon
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconUsb } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidStreamInput } from '../../../utils/stream_input'

const RtspExamples = () => (
  <Stack gap="xs">
    <Card bg="blue.0">
      <Text size="xs" fw="bold" mb="sm">
        <FormattedMessage id="streams.rtspUrl.examples" />
      </Text>

      <List size="xs" spacing="sm" withPadding>
        <List.Item>
          Axis -
          rtsp://[username]:[password]@[IP_address]:[port]/axis-media/media.amp
        </List.Item>

        <List.Item>
          Bosch - rtsp://[username]:[password]@[IP_address]:[port]/rtsp_tunnel
        </List.Item>

        <List.Item>
          Dahua -
          rtsp://[username]:[password]@[IP_address]:[port]/cam/realmonitor?channel=[channel]&subtype=[stream_type]
        </List.Item>

        <List.Item>
          Hikvision -
          rtsp://[username]:[password]@[IP_address]:[port]/Streaming/Channels/[channel_number]
        </List.Item>
      </List>
    </Card>

    <Card bg="blue.0">
      <Group wrap="nowrap" gap="xs">
        <ThemeIcon size="md" variant="transparent">
          <IconUsb style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>

        <Text size="xs">
          <FormattedMessage
            id="streams.usb.hint"
            values={{
              usbExample: <Code>/dev/video0</Code>
            }}
          />
        </Text>
      </Group>
    </Card>
  </Stack>
)

export type DeviceOption = {
  value: string
  label: string
}

export type FormValues = {
  deviceId: string
  streamName: string
  rtspUrl: string
}

type AddStreamFormProps = {
  deviceId: string
  deviceOptions: DeviceOption[]
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

export const AddStreamForm = ({
  deviceId,
  deviceOptions,
  onCancel,
  onSubmit
}: AddStreamFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      deviceId: deviceId || '',
      streamName: '',
      rtspUrl: ''
    },

    validate: {
      streamName: isNotEmpty(
        intl.formatMessage({ id: 'streams.validation.enterStreamName' })
      ),
      rtspUrl: (value) => {
        if (!isValidStreamInput(value)) {
          return intl.formatMessage({
            id: 'streams.validation.enterValidRtspUrl'
          })
        }
        return null
      }
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack gap="lg">
        <TextInput
          label={<FormattedMessage id="streams.streamName" />}
          autoFocus
          data-autofocus // required for auto focus inside the modal
          {...form.getInputProps('streamName')}
        />

        <Select
          label={<FormattedMessage id="streams.selectDevice" />}
          data={deviceOptions}
          value={form.values.deviceId}
          allowDeselect={false}
          onChange={(value) => form.setFieldValue('deviceId', value as string)}
        />

        <TextInput
          label={<FormattedMessage id="streams.rtspUrl" />}
          {...form.getInputProps('rtspUrl')}
        />

        <RtspExamples />

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button type="submit">
            <FormattedMessage id="streams.addCameraStream" />
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
