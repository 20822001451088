import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { workspaceApi } from '@/api/workspaceApi'
import { objectToQueryString } from '@/utils/url'

export const workspaceQueryKeys = {
  all: ['workspaces'] as const,
  members: ({
    pagination,
    ordering,
    filter,
    searchQuery,
    workspaceId
  }: GetWorkspaceMembersProps) =>
    [
      ...workspaceQueryKeys.all,
      'members',
      pagination,
      ordering,
      filter,
      searchQuery,
      workspaceId
    ] as const,
  member: (id: string) => [...workspaceQueryKeys.all, 'member', id] as const
}

export const useCreateWorkspace = () => {
  return useMutation({
    mutationFn: workspaceApi.createWorkspace
  })
}

export const useUpdateWorkspace = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.updateWorkspace,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: workspaceQueryKeys.member(id)
      })
  })
}

export const useInviteMembers = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.inviteMembers,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

type Pagination = {
  limit: number
  offset: number
}

export type GetWorkspaceMembersProps = {
  workspaceId: string
  pagination: Pagination
  ordering: string
  searchQuery: string
  filter: Record<string, string | number>
}

export const useGetWorkspaceMembers = ({
  workspaceId,
  pagination,
  ordering,
  searchQuery,
  filter
}: GetWorkspaceMembersProps) => {
  const pageUrl = `/v1/workspaces/${workspaceId}/members/?${objectToQueryString(
    { ordering, search: searchQuery, ...pagination, ...filter }
  )}`

  return useQuery({
    queryKey: workspaceQueryKeys.members({
      pagination,
      ordering,
      filter,
      searchQuery,
      workspaceId
    }),
    queryFn: () => workspaceApi.getWorkspaceMembers({ pageUrl }),
    placeholderData: keepPreviousData
  })
}

export const useUpdateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.updateWorkspaceMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

export const useResendMembershipInvite = () => {
  return useMutation({
    mutationFn: workspaceApi.resendInvite
  })
}

export const useDeactivateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.deactivateWorkspaceMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

export const useAcceptWorkspaceInvitation = () => {
  return useMutation({
    mutationFn: workspaceApi.acceptWorkspaceInvitation
  })
}

type GetUserWorkspaceMembershipProps = {
  id: string
  enabled: boolean
}

export const useGetWorkspaceMembership = ({
  id,
  enabled
}: GetUserWorkspaceMembershipProps) => {
  return useQuery({
    queryKey: workspaceQueryKeys.member(id),
    queryFn: () => workspaceApi.getMyMembership(id),
    enabled
  })
}
