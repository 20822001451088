import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { modelAPI } from '@/api/modelApi'
import { MLModelStatus, MLModelType } from '@/types/model'
import { objectToQueryString } from '@/utils/url'

type ModelListQueryParams = {
  limit?: number
  ordering?: string
  is_master_model?: boolean
  is_library?: boolean
}

type AppModelListQueryParams = {
  limit?: number
  ordering?: string
  status?: MLModelStatus | MLModelStatus[]
  id?: string
  model_type?: MLModelType
}

export const modelQueryKeys = {
  all: ['models'] as const,
  listMLModels: (params: ModelListQueryParams) =>
    [...modelQueryKeys.all, 'ml-models', params] as const,
  listAppMLModels: (appId: string, params: AppModelListQueryParams) =>
    [...modelQueryKeys.all, 'app-ml-models', appId, params] as const,
  details: (modelId: string) =>
    [...modelQueryKeys.all, 'details', modelId] as const,
  detailsList: (modelIds: string[]) =>
    [...modelQueryKeys.all, 'details-list', modelIds] as const
}

export const useGetMLModels = (params?: ModelListQueryParams) => {
  const queryParams = {
    limit: 100,
    ordering: 'created_at',
    ...params
  }

  const queryString = objectToQueryString(queryParams)
  const initialPageParam = `/v1/applications/models/?${queryString}`

  return useInfiniteQuery({
    queryKey: modelQueryKeys.listMLModels(queryParams),
    queryFn: ({ pageParam }) => modelAPI.getMLModels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam
  })
}

export const useGetAppMLModels = (
  appId: string,
  params?: AppModelListQueryParams
) => {
  const queryParams = {
    limit: 100,
    ordering: 'created_at',
    ...params
  }

  const queryString = objectToQueryString(queryParams)
  const initialPageParam = `/v1/applications/${appId}/ml-models/?${queryString}`

  return useInfiniteQuery({
    queryKey: modelQueryKeys.listAppMLModels(appId, queryParams),
    queryFn: ({ pageParam }) => modelAPI.getMLModels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam
  })
}

export const useGetModelDetails = (modelId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: modelQueryKeys.details(modelId),
    queryFn: () => modelAPI.getModelDetails(modelId),
    enabled
  })
}

export const useGetMultipleModelDetails = (modelIds: string[]) => {
  return useQuery({
    queryKey: modelQueryKeys.detailsList(modelIds),
    queryFn: () => {
      return Promise.all(
        modelIds.map((modelId) => modelAPI.getModelDetails(modelId))
      )
    }
  })
}

export const useCreateModel = () => {
  return useMutation({
    mutationFn: modelAPI.createModel
  })
}

export const useUpdateModel = (modelId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: modelAPI.updateModel,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: modelQueryKeys.details(modelId)
      })
  })
}

export const useInvalidateModelDetails = (modelId: string) => {
  const queryClient = useQueryClient()

  const invalidateModelDetails = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: modelQueryKeys.details(modelId)
    })
  }, [queryClient, modelId])

  return {
    invalidateModelDetails
  }
}

export const useDeleteModel = () => {
  return useMutation({
    mutationFn: modelAPI.deleteModel
  })
}
