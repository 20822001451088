import { ReactNode, createContext, useContext } from 'react'
import { ApplicationType } from '@/types/app'
import { MLModel } from '@/types/model'

type ContextProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
}

const initialValues = {
  appType: ApplicationType.SingleStage,
  primaryModel: null,
  secondaryModel: null
}

const LogicSelectionContext = createContext<ContextProps>(initialValues)

type LogicSelectionProviderProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  children: ReactNode
}

export const LogicSelectionProvider = ({
  appType,
  primaryModel,
  secondaryModel,
  children
}: LogicSelectionProviderProps) => {
  return (
    <LogicSelectionContext.Provider
      value={{ appType, primaryModel, secondaryModel }}
    >
      {children}
    </LogicSelectionContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useLogicSelectionContext = () => {
  return useContext(LogicSelectionContext)
}
