import { useHotkeys } from '@mantine/hooks'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Fragment, useState } from 'react'
import { ParameterType } from '@/types/businessLogic'
import { AnnotationAreaContainer } from './AnnotationAreaContainer'
import { LineCrossing } from './LineCrossing/LineCrossing'
import { LineCrossingInProgress } from './LineCrossing/LineCrossingInProgress'
import {
  LINE_PREFIX_NAME,
  useLineCrossing
} from './LineCrossing/useLineCrossing'
import { Polygon } from './Roi/Polygon'
import { PolygonInProgress } from './Roi/PolygonInProgress'
import { ROI_NAME_PREFIX, useDrawRoi } from './Roi/useDrawRoi'
import { StageWithZoom } from './StageWithZoom/StageWithZoom'

export type AnnotationValues = {
  type: ParameterType
  name: string
  color: string
  coordinates: number[][]
}

type AnnotationAreaProps = {
  enabledLogicParameters: ParameterType[]
  imageUrl?: string
  isImageLoading?: boolean
  width: number
  height: number
  annotations: AnnotationValues[]
  onAnnotationAdd: (annotation: AnnotationValues) => void
  onAnnotationChange: (index: number, annotation: AnnotationValues) => void
  onImageRefresh: () => void
}

export const AnnotationArea = ({
  enabledLogicParameters,
  imageUrl,
  isImageLoading,
  width,
  height,
  annotations,
  onAnnotationAdd,
  onAnnotationChange,
  onImageRefresh
}: AnnotationAreaProps) => {
  const [annotationMode, setAnnotationMode] = useState<'line_crossing' | 'roi'>(
    'line_crossing'
  )
  const [activeShape, setActiveShape] = useState<
    Konva.Shape | Konva.Stage | null
  >(null)

  const lineCrossingNames = annotations
    .filter((annotation) => annotation.type === ParameterType.LineCrossing)
    .map((annotation) => annotation.name)

  const roiNames = annotations
    .filter((annotation) => annotation.type === ParameterType.ROI)
    .map((annotation) => annotation.name)

  const {
    color: lineCrossingColor,
    step: lineCrossingStep,
    linePoints,
    directionPoints,
    lineFlattenedPoints,
    directionFlattenedPoints,
    isCompleted: isLineCrossingCompleted,
    start: startLineCrossing,
    exit: exitLineCrossing,
    handleMouseMove: handleLineCrossingMouseMove,
    handleMouseDown: handleLineCrossingMouseDown
  } = useLineCrossing({
    lineNumber:
      Number(
        (lineCrossingNames[lineCrossingNames.length - 1] || '0').replace(
          LINE_PREFIX_NAME,
          ''
        )
      ) + 1,
    onLineCrossingAdd: onAnnotationAdd
  })

  const {
    color: roiColor,
    points: roiPoints,
    flattenedPoints: roiFlattenedPoints,
    isCompleted: isRoiCompleted,
    exit: exitRoi,
    start: startRoi,
    handleMouseMove: handleRoiMouseMove,
    handleMouseDown: handleRoiMouseDown,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint
  } = useDrawRoi({
    roiNumber:
      Number(
        (roiNames[roiNames.length - 1] || '0').replace(ROI_NAME_PREFIX, '')
      ) + 1,
    onRoiAdd: onAnnotationAdd
  })

  const exit = () => {
    exitLineCrossing()
    exitRoi()
  }

  useHotkeys([['Escape', exit]])

  const isAnnotating = !isLineCrossingCompleted || !isRoiCompleted

  const handleAddLineCrossing = () => {
    exit()
    setAnnotationMode('line_crossing')
    startLineCrossing()
  }

  const handleAddRoi = () => {
    exit()
    setAnnotationMode('roi')
    startRoi()
  }

  const handleStageMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    setActiveShape(e.target)

    if (annotationMode === 'line_crossing') {
      handleLineCrossingMouseDown(e)
    } else {
      handleRoiMouseDown(e)
    }
  }

  const handleStageMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!isAnnotating) {
      return
    }

    if (annotationMode === 'line_crossing') {
      handleLineCrossingMouseMove(e)
    } else {
      handleRoiMouseMove(e)
    }
  }

  return (
    <AnnotationAreaContainer
      showRoi={enabledLogicParameters.includes(ParameterType.ROI)}
      showLineCrossing={enabledLogicParameters.includes(
        ParameterType.LineCrossing
      )}
      isRoiInProgress={annotationMode === 'roi' && !isRoiCompleted}
      isLineCrossingInProgress={
        annotationMode === 'line_crossing' && !isLineCrossingCompleted
      }
      onAddLineCrossing={handleAddLineCrossing}
      onAddRoi={handleAddRoi}
    >
      <StageWithZoom
        width={width}
        height={height}
        imageUrl={imageUrl}
        isLoading={isImageLoading}
        isAnnotating={isAnnotating}
        onMouseMove={handleStageMouseMove}
        onMouseDown={handleStageMouseDown}
        onRefreshClick={onImageRefresh}
      >
        {annotations.map((annotation, annotationIndex) => {
          return annotation.type === ParameterType.LineCrossing ? (
            <Fragment key={annotation.name}>
              <LineCrossing
                variant="line"
                name={annotation.name}
                color={annotation.color}
                initialPoints={annotation.coordinates.slice(2, 4)}
                onCoordinateChange={(coordinates) => {
                  onAnnotationChange(annotationIndex, {
                    ...annotation,
                    coordinates: annotation.coordinates
                      .slice(0, 2)
                      .concat(coordinates)
                  })
                }}
              />

              <LineCrossing
                variant="direction"
                name={annotation.name}
                color={annotation.color}
                initialPoints={annotation.coordinates.slice(0, 2)}
                onCoordinateChange={(coordinates) => {
                  onAnnotationChange(annotationIndex, {
                    ...annotation,
                    coordinates: coordinates.concat(
                      annotation.coordinates.slice(2, 4)
                    )
                  })
                }}
              />
            </Fragment>
          ) : (
            <Polygon
              key={annotation.name}
              name={annotation.name}
              color={annotation.color}
              initialPoints={annotation.coordinates}
              isActivated={
                activeShape?.getParent()?.name() === annotation.name &&
                !isAnnotating
              }
              draggable
              onCoordinateChange={(coordinates) => {
                onAnnotationChange(annotationIndex, {
                  ...annotation,
                  coordinates
                })
              }}
            />
          )
        })}

        {annotationMode === 'line_crossing' && !isLineCrossingCompleted && (
          <>
            <LineCrossingInProgress
              variant="line"
              color={lineCrossingColor}
              points={linePoints}
              flattenedPoints={lineFlattenedPoints}
            />

            {lineCrossingStep === 'direction' && (
              <LineCrossingInProgress
                variant="direction"
                color={lineCrossingColor}
                points={directionPoints}
                flattenedPoints={directionFlattenedPoints}
              />
            )}
          </>
        )}

        {annotationMode === 'roi' && !isRoiCompleted && (
          <PolygonInProgress
            color={roiColor}
            points={roiPoints}
            flattenedPoints={roiFlattenedPoints}
            onMouseOverStartPoint={handleMouseOverStartPoint}
            onMouseOutStartPoint={handleMouseOutStartPoint}
          />
        )}
      </StageWithZoom>
    </AnnotationAreaContainer>
  )
}
