import {
  ActionIcon,
  Anchor,
  Badge,
  Card,
  Center,
  Menu,
  Text,
  ThemeIcon,
  rem
} from '@mantine/core'
import {
  IconBox,
  IconDotsVertical,
  IconPencil,
  IconTrash
} from '@tabler/icons-react'
import { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { AnimatedCard } from '@/components/ui-shared/AnimatedCard/AnimatedCard'

const PlaceholderIcon = () => (
  <Center h={115} bg="gray.0">
    <ThemeIcon color="brand-secondary" variant="transparent" size={42}>
      <IconBox style={{ width: '100%', height: '100%' }} />
    </ThemeIcon>
  </Center>
)

type ApplicationCardProps = {
  appName: string
  appDetailsLink: string
  isMultiStage?: boolean
  onRename: () => void
  onDelete: () => void
}

export const ApplicationCard = ({
  appName,
  appDetailsLink,
  isMultiStage,
  onRename,
  onDelete
}: ApplicationCardProps) => {
  const handleRename = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onRename()
  }

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onDelete()
  }

  return (
    <Anchor component={Link} to={appDetailsLink} underline="never">
      <AnimatedCard h={175}>
        <Card.Section pos="relative" withBorder>
          <PlaceholderIcon />

          {isMultiStage && (
            <Badge pos="absolute" top={4} right={4} color="blue" size="xs">
              <FormattedMessage id="apps.multiStage" />
            </Badge>
          )}
        </Card.Section>

        <Card.Section pos="relative" pt="lg" pb="md" inheritPadding>
          <Text size="sm" fw="bold" ta="center" truncate>
            {appName}
          </Text>

          <Menu position="bottom-start" width={200}>
            <Menu.Target>
              <ActionIcon
                pos="absolute"
                top={2}
                right={2}
                variant="subtle"
                size="sm"
                radius="xl"
                onClick={(event) => event.preventDefault()}
              >
                <IconDotsVertical style={{ width: '80%', height: '80%' }} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconPencil style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleRename}
              >
                <FormattedMessage id="rename" />
              </Menu.Item>

              <Menu.Item
                color="red"
                leftSection={
                  <IconTrash style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleDelete}
              >
                <FormattedMessage id="delete" />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Card.Section>
      </AnimatedCard>
    </Anchor>
  )
}
