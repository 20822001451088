import {
  Accordion,
  Badge,
  Card,
  Code,
  Grid,
  ScrollArea,
  Text
} from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { Event } from '@/types/event'
import { EventInfoCard } from './EventInfoCard/EventInfoCard'
import { MeasurementInfo } from './MeasurementInfo/MeasurementInfo'

type EventDetailRightPaneProps = {
  event: Event
}

export const EventDetailRightPane = ({ event }: EventDetailRightPaneProps) => {
  const intl = useIntl()

  const outputStream = event.output.stream

  const detections = event.detections.split('->')
  const detectionName = detections[detections.length - 1]

  return (
    <Grid.Col span={{ base: 12, md: 5, lg: 5 }}>
      <ScrollArea h="80vh">
        <EventInfoCard title={intl.formatMessage({ id: 'events.detections' })}>
          <Card.Section py="lg" inheritPadding>
            <Badge>{detectionName}</Badge>
          </Card.Section>
        </EventInfoCard>

        {event.measurement && (
          <EventInfoCard
            title={intl.formatMessage({ id: 'events.measurement' })}
          >
            <Card.Section>
              <MeasurementInfo
                id={event.measurement.id}
                width={event.measurement.dimensions.width}
                height={event.measurement.dimensions.height}
                depth={event.measurement.dimensions.depth}
                sizeUnit={event.measurement.dimensions.unit}
                weight={event.measurement.weight.value}
                weightUnit={event.measurement.weight.unit}
              />
            </Card.Section>
          </EventInfoCard>
        )}

        <EventInfoCard
          title={intl.formatMessage({ id: 'events.configuration' })}
        >
          <Card.Section p="0" inheritPadding>
            <Accordion
              defaultValue="parameters"
              styles={{
                content: {
                  padding: 0
                }
              }}
            >
              <Accordion.Item value="parameters" style={{ border: 'none' }}>
                <Accordion.Control style={{ borderRadius: '8px' }} bg="gray.1">
                  <Text size="sm" fw={600}>
                    <FormattedMessage id="events.configuration.parameters" />
                  </Text>
                </Accordion.Control>

                <Accordion.Panel bg="gray.0">
                  <ScrollArea w="100%" h={420}>
                    <Code bg="gray.0" block>
                      {JSON.stringify(outputStream.business_logics, null, 4)}
                    </Code>
                  </ScrollArea>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Card.Section>
        </EventInfoCard>
      </ScrollArea>
    </Grid.Col>
  )
}
