import { Box, Button, Group, Stack, Text } from '@mantine/core'
import { IconRotate } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetEdgeClientVersion,
  useTriggerEdgeClientAction
} from '@/queries/deviceQueries'
import { usePollTaskStatus } from '@/queries/generalQueries'
import { showToast } from '@/theme/notifications'
import { EdgeClientVersionInfo } from './EdgeClientVersionInfo'

type EdgeClientAction = 'update' | 'restart'

type EdgeClientManagerProps = {
  deviceId: string
  currentVersion: string
  onUpdated: () => void
}

export const EdgeClientManager = ({
  deviceId,
  currentVersion,
  onUpdated
}: EdgeClientManagerProps) => {
  const intl = useIntl()
  const [pollTaskId, setPollTaskId] = useState('')
  const [taskType, setTaskType] = useState<EdgeClientAction | ''>('')

  const { data, isLoading } = useGetEdgeClientVersion()
  const { mutateAsync, isPending } = useTriggerEdgeClientAction()

  const { data: celeryTaskStatusData, isFetching: isStatusFetching } =
    usePollTaskStatus(pollTaskId, pollTaskId !== '')

  const isUpdating =
    taskType === 'update' &&
    (isPending ||
      isStatusFetching ||
      celeryTaskStatusData?.task_status === 'PENDING')

  const isRestarting =
    taskType === 'restart' &&
    (isPending ||
      isStatusFetching ||
      celeryTaskStatusData?.task_status === 'PENDING')

  useEffect(() => {
    if (celeryTaskStatusData?.task_status === 'SUCCESS') {
      if (taskType === 'restart') {
        showToast(
          intl.formatMessage({ id: 'devices.client.restart.success' }),
          'success'
        )
      }

      setPollTaskId('')
      setTaskType('')
      onUpdated()
    }

    if (celeryTaskStatusData?.task_status === 'FAILURE') {
      showToast(intl.formatMessage({ id: 'devices.client.error' }), 'error')

      setTaskType('')
      setPollTaskId('')
    }
  }, [celeryTaskStatusData, intl, onUpdated, taskType])

  const latestVersion = data?.trim()

  const hasLatestVersion = currentVersion === latestVersion

  const handleEdgeClientAction = async (action: EdgeClientAction) => {
    setTaskType(action)

    try {
      const { task_id } = await mutateAsync({
        deviceId,
        action
      })

      setPollTaskId(task_id)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'devices.client.error' })

      showToast(message, 'error')

      setTaskType('')
    }
  }

  return (
    <Stack>
      <Box>
        <Text fw="bold" size="sm" mb={4}>
          <FormattedMessage id="devices.client.version" />
        </Text>

        <EdgeClientVersionInfo
          currentVersion={currentVersion}
          hasLatestVersion={hasLatestVersion}
          isLoading={isLoading}
        />
      </Box>

      <Group>
        {latestVersion && !hasLatestVersion && (
          <Button
            size="xs"
            color="green"
            miw={160}
            loading={isUpdating}
            disabled={isRestarting}
            onClick={() => void handleEdgeClientAction('update')}
          >
            <FormattedMessage
              id="devices.client.update.install"
              values={{
                version: `(v${latestVersion})`
              }}
            />
          </Button>
        )}

        <Button
          size="xs"
          miw={120}
          leftSection={<IconRotate size={16} />}
          loading={isRestarting}
          disabled={isUpdating}
          onClick={() => void handleEdgeClientAction('restart')}
        >
          <FormattedMessage id="devices.client.restart" />
        </Button>
      </Group>
    </Stack>
  )
}
