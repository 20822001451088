import { Box, Loader, LoadingOverlay, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useCropDataset, useGetDatasetVersions } from '@/queries/datasetQueries'
import { usePollTaskStatus } from '@/queries/generalQueries'
import { showToast } from '@/theme/notifications'
import { Dataset } from '@/types/dataset'
import { ImageCropForm, SubmitValues } from './ImageCropForm'

type ImageCropHandlerProps = {
  targetDataset: Dataset
  onCropSuccess: () => void
  onSkip: () => void
}

export const ImageCropHandler = ({
  targetDataset,
  onCropSuccess,
  onSkip
}: ImageCropHandlerProps) => {
  const intl = useIntl()
  const [taskId, setTaskId] = useState<string>('')

  const { data: versionList, isLoading: isVersionListLoading } =
    useGetDatasetVersions(targetDataset.id)

  const { mutateAsync: cropDataset, isPending } = useCropDataset()

  const { data: taskStatusData, isLoading: isLoadingTaskStatus } =
    usePollTaskStatus(taskId, taskId !== '')

  useEffect(() => {
    if (taskStatusData?.task_status === 'SUCCESS') {
      onCropSuccess()
    }

    if (taskStatusData?.task_status === 'FAILURE') {
      showToast(intl.formatMessage({ id: 'datasets.crop.error' }), 'error')
    }

    // onCropSuccess is a dependency, but it won't change
  }, [taskStatusData, intl]) // eslint-disable-line react-hooks/exhaustive-deps

  const versions = versionList?.pages?.flatMap((page) => page.results) || []

  const isProcessing =
    isVersionListLoading ||
    isPending ||
    isLoadingTaskStatus ||
    taskStatusData?.task_status === 'PENDING'

  const handleSubmit = async (values: SubmitValues) => {
    try {
      const { task_id } = await cropDataset({
        sourceVersionId: values.sourceDatasetVersionId,
        data: {
          dataset_version_id: versions[0]?.id,
          label_ids: values.label_ids
        }
      })

      setTaskId(task_id)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.crop.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative" pt="md">
      <LoadingOverlay
        visible={isProcessing}
        loaderProps={{
          children: (
            <Stack align="center" gap="xl">
              <Loader size="md" />

              <Text size="sm">
                <FormattedMessage id="datasets.crop.processing" />
              </Text>
            </Stack>
          )
        }}
      />

      <ImageCropForm
        onSubmit={(values) => void handleSubmit(values)}
        onSkip={onSkip}
      />
    </Box>
  )
}
