import { Box, LoadingOverlay } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { accountApi } from '@/api/accountApi'
import { AuthLayout } from '@/components/layout/AuthLayout/AuthLayout'
import { RouterPath } from '@/router/paths'
import { useLogin } from '@/session/useLogin'
import { showToast } from '@/theme/notifications'
import { ErrorBanner } from '../ui-shared/ErrorBanner/ErrorBanner'
import {
  FormValues,
  SetNewPasswordForm
} from './SetNewPasswordForm/SetNewPasswordForm'

const messages = {
  passwordChanged: {
    id: 'passwordReset.passwordChanged'
  }
}

const ApiError = () => {
  return (
    <Box mb="lg">
      <ErrorBanner>
        <FormattedMessage id="passwordReset.passwordChangeError" />
      </ErrorBanner>
    </Box>
  )
}

type SetNewPasswordHandlerProps = {
  uid: string
  email: string
  token: string
}

export const SetNewPasswordHandler = ({
  email,
  token,
  uid
}: SetNewPasswordHandlerProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { login } = useLogin()

  const { mutate, isPending, isError } = useMutation({
    mutationFn: accountApi.resetPasswordConfirm,
    onSuccess: async (_, variables) => {
      try {
        const { password } = variables
        await login({ email, password })
      } catch {
        navigate(RouterPath.login)
      }

      showToast(intl.formatMessage(messages.passwordChanged), 'success')
    }
  })

  const handleSubmit = (formValues: FormValues) => {
    const { password } = formValues

    if (!isPending) {
      mutate({
        password,
        token,
        uid
      })
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {isError && <ApiError />}

        <SetNewPasswordForm onSubmit={handleSubmit} />
      </Box>
    </AuthLayout>
  )
}
