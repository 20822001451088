import { PaginatedResponse } from '@/types/pagination'
import {
  Workspace,
  WorkspaceCreateUpdateParam,
  WorkspaceMember,
  WorkspaceMemberRole
} from '@/types/workspace'
import { vpApi } from './vpApi'

const createWorkspace = async (payload: WorkspaceCreateUpdateParam) => {
  const res = await vpApi.post<Workspace>('/v1/workspaces/', payload)
  return res.data
}

const getUserWorkspace = async (workspaceId: string) => {
  const res = await vpApi.get<Workspace>(`/v1/workspaces/${workspaceId}/`)
  return res.data
}

export type UpdateWorkspaceParam = {
  workspaceId: string
  payload: WorkspaceCreateUpdateParam
}

const updateWorkspace = async ({
  workspaceId,
  payload
}: UpdateWorkspaceParam) => {
  return vpApi.patch(`/v1/workspaces/${workspaceId}/`, payload)
}

export type MemberInvitation = {
  email: string
  role: WorkspaceMemberRole
}

export type InviteMembersParam = {
  workspaceId: string
  members: MemberInvitation[]
}

const inviteMembers = async ({ workspaceId, members }: InviteMembersParam) => {
  return vpApi.post(`/v1/workspaces/${workspaceId}/invite/`, members)
}

export type GetWorkspaceMembersResponse = PaginatedResponse<WorkspaceMember[]>

type GetApplicationsParams = {
  pageUrl: string
}

const getWorkspaceMembers = async ({ pageUrl }: GetApplicationsParams) => {
  const res = await vpApi.get<GetWorkspaceMembersResponse>(pageUrl)
  return res.data
}

type UpdateMemberParams = {
  workspaceId: string
  memberId: string
  role: WorkspaceMemberRole
}

const updateWorkspaceMember = ({
  workspaceId,
  memberId,
  role
}: UpdateMemberParams) => {
  return vpApi.patch(`/v1/workspaces/${workspaceId}/members/${memberId}/`, {
    role
  })
}

type ResendInviteParams = {
  workspaceId: string
  memberId: string
}

const resendInvite = ({ workspaceId, memberId }: ResendInviteParams) => {
  return vpApi.post(
    `/v1/workspaces/${workspaceId}/members/${memberId}/resend-invite/`
  )
}

type DeactivateMemberParams = {
  workspaceId: string
  memberId: string
}

const deactivateWorkspaceMember = async ({
  workspaceId,
  memberId
}: DeactivateMemberParams) => {
  return await vpApi.post(
    `/v1/workspaces/${workspaceId}/members/${memberId}/deactivate/`
  )
}

type AcceptWorkspaceInvitationParams = {
  workspaceId: string
  token: string
}

const acceptWorkspaceInvitation = ({
  workspaceId,
  token
}: AcceptWorkspaceInvitationParams) => {
  return vpApi.post(`/v1/workspaces/${workspaceId}/accept-invite/`, { token })
}

const getMyMembership = async (workspaceId: string) => {
  const res = await vpApi.get<WorkspaceMember>(
    `/v1/workspaces/${workspaceId}/my-membership/`
  )
  return res.data
}

export const workspaceApi = {
  createWorkspace,
  getUserWorkspace,
  updateWorkspace,
  getWorkspaceMembers,
  inviteMembers,
  updateWorkspaceMember,
  resendInvite,
  deactivateWorkspaceMember,
  acceptWorkspaceInvitation,
  getMyMembership
}
