import { Badge, Radio, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { RadioCard } from '@/components/ui-shared/RadioCard/RadioCard'
import { ApplicationType } from '@/types/app'

type AppTypeSelectorProps = {
  selectedType: ApplicationType
  onChange: (type: ApplicationType) => void
}

export const AppTypeSelector = ({
  selectedType,
  onChange
}: AppTypeSelectorProps) => {
  return (
    <Stack gap="xs">
      <Text size="sm" fw={600}>
        <FormattedMessage id="apps.selectType" />
      </Text>

      <Radio.Group
        value={String(selectedType)}
        onChange={(value) => onChange(Number(value))}
      >
        <Stack gap="xs">
          <RadioCard
            isSelected={selectedType === ApplicationType.SingleStage}
            value={String(ApplicationType.SingleStage)}
            title={<FormattedMessage id="apps.singleStage" />}
            description={<FormattedMessage id="apps.singleStage.description" />}
          />

          <RadioCard
            isSelected={selectedType === ApplicationType.MultiStage}
            value={String(ApplicationType.MultiStage)}
            title={<FormattedMessage id="apps.multiStage" />}
            badge={
              <Badge variant="outline" color="brand-secondary" size="sm">
                <FormattedMessage id="advanced" />
              </Badge>
            }
            description={<FormattedMessage id="apps.multiStage.description" />}
          />
        </Stack>
      </Radio.Group>
    </Stack>
  )
}
