import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useStopDeployment } from '@/queries/deploymentQueries'
import { showToast } from '@/theme/notifications'

type StopDeploymentConfirmationProps = {
  opened: boolean
  deploymentId: string
  onStopTriggered: () => void
  onClose: () => void
}

export const StopDeploymentConfirmation = ({
  opened,
  deploymentId,
  onStopTriggered,
  onClose
}: StopDeploymentConfirmationProps) => {
  const intl = useIntl()
  const { mutateAsync: stopDeployment, isPending } = useStopDeployment()

  const handleStopDeployment = async () => {
    try {
      await stopDeployment(deploymentId)
      showToast(
        intl.formatMessage({ id: 'deployments.stopApp.inProgress' }),
        'info',
        { autoClose: 3000 }
      )
      onStopTriggered()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'deployments.stopApp.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="deployments.stopApp" />}
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage id="deployments.stopApp.confirmation" />
        </Text>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button onClick={() => void handleStopDeployment()}>
            <FormattedMessage id="deployments.stopApp" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
