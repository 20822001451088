import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageType } from 'konva/lib/Stage'
import { useMemo, useState } from 'react'
import { getRandomColor } from '@/theme/randomColor'
import { ParameterType } from '@/types/businessLogic'
import { AnnotationValues } from '../AnnotationArea'

export const LINE_PREFIX_NAME = 'line'

type UseLineCrossingParams = {
  lineNumber: number
  onLineCrossingAdd: (annotation: AnnotationValues) => void
}

export const useLineCrossing = ({
  lineNumber,
  onLineCrossingAdd
}: UseLineCrossingParams) => {
  const [linePoints, setLinePoints] = useState<number[][]>([])
  const [directionPoints, setDirectionPoints] = useState<number[][]>([])
  const [position, setPosition] = useState([0, 0])
  const [color, setColor] = useState(getRandomColor())
  const [step, setStep] = useState<'line' | 'direction'>('line')
  const [isCompleted, setIsCompleted] = useState(true)

  const lineFlattenedPoints = useMemo(() => {
    return linePoints
      .concat(linePoints.length === 2 ? [] : position)
      .reduce((a, b) => a.concat(b), [])
  }, [linePoints, position])

  const directionFlattenedPoints = useMemo(() => {
    return directionPoints
      .concat(directionPoints.length === 2 ? [] : position)
      .reduce((a, b) => a.concat(b), [])
  }, [directionPoints, position])

  const reset = () => {
    setLinePoints([])
    setDirectionPoints([])
    setPosition([0, 0])
    setStep('line')
    setColor(getRandomColor())
  }

  const exit = () => {
    if (isCompleted) {
      return
    }

    reset()
    setIsCompleted(true)
  }

  const start = () => {
    reset()
    setIsCompleted(false)
  }

  const getMousePos = (stage: StageType | null) => {
    const pointerPosition = stage?.getRelativePointerPosition()

    if (pointerPosition) {
      return [pointerPosition.x, pointerPosition.y]
    }

    return [0, 0]
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isCompleted) {
      return
    }

    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)

    if (step === 'line') {
      setLinePoints([...linePoints, mousePos])

      if (linePoints.length === 1) {
        setStep('direction')
      }
    }

    if (step === 'direction') {
      if (directionPoints.length === 1) {
        onLineCrossingAdd({
          type: ParameterType.LineCrossing,
          name: `${LINE_PREFIX_NAME}${lineNumber}`,
          color,
          coordinates: [...directionPoints, [...mousePos], ...linePoints]
        })

        exit()
      } else {
        setDirectionPoints([...directionPoints, mousePos])
      }
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  return {
    color,
    step,
    linePoints,
    directionPoints,
    lineFlattenedPoints,
    directionFlattenedPoints,
    isCompleted,
    start,
    exit,
    handleMouseDown,
    handleMouseMove
  }
}
