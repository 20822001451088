import { BusinessLogic } from './businessLogic'
import { MilestoneCameraAttributes } from './device'

export type DeploymentConfig = {
  application_id: string
  primary_model_id: string
  secondary_model_id?: string
  config_width: number
  config_height: number
  streams: StreamConfig[]
}

type StreamConfig = {
  stream_id: string
  device_id: string
  frame_width: number
  frame_height: number
  eglsink: boolean
  business_logics: BusinessLogic[]
  event_endpoints: EventEndpoint[]
}

export type EventEndpoint = {
  service: EventDestination
  endpoint?: string
  attributes?: MilestoneCameraAttributes
}

export enum EventDestination {
  Milestone = 'milestone',
  Visionplatform = 'visionplatform'
}

export type Deployment = {
  id: string
  application: string
  status: DeploymentStatus
  status_display: string
  error_body: string
  settings_payload: StreamConfig
  primary_ml_model: {
    id: string
    name: string
  }
  secondary_ml_model: {
    id: string
    name: string
  } | null
  created_at: string
}

export enum DeploymentStatus {
  InProgress = 1,
  Inactive = 2,
  Running = 3,
  Failed = 4,
  Removing = 5,
  Stopping = 6,
  Stopped = 7
}
