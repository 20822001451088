import { Button, Stack, Text } from '@mantine/core'
import { IconMessageChatbot } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type WorkflowHelpSectionProps = {
  onChatbotClick: () => void
}

export const WorkflowHelpSection = ({
  onChatbotClick
}: WorkflowHelpSectionProps) => {
  return (
    <Stack gap="xs">
      <Text size="sm" fw={600}>
        <FormattedMessage id="apps.workflowHelp.title" />
      </Text>

      <Text size="sm">
        <FormattedMessage id="apps.workflowHelp.description" />
      </Text>

      <div>
        <Button
          size="xs"
          leftSection={<IconMessageChatbot size={16} />}
          onClick={onChatbotClick}
        >
          <FormattedMessage id="apps.workflowHelp.askChatbot" />
        </Button>
      </div>
    </Stack>
  )
}
