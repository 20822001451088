import { Badge, Group, MultiSelect, MultiSelectProps, rem } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import classes from './ObjectMultiSelect.module.css'
import { LabelOption } from './ObjectSelect'

export const ObjectMultiSelect = (props: MultiSelectProps) => {
  const intl = useIntl()
  const { value } = props

  return (
    <MultiSelect
      classNames={{ pill: classes.pill }}
      size="xs"
      placeholder={
        value && value.length === 0
          ? intl.formatMessage({
              id: 'logic.rules.object.select'
            })
          : undefined
      }
      comboboxProps={{
        width: 300,
        position: 'bottom-start'
      }}
      styles={{
        pill: {
          backgroundColor: 'transparent',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          paddingInline: '0',
          fontSize: rem(11)
        },
        inputField: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: rem(11)
        }
      }}
      renderOption={({ option, checked }) => {
        const { label, color } = option as LabelOption

        return (
          <Group flex="1" gap="xs">
            <Badge color={color} size="sm">
              {label}
            </Badge>

            {checked && (
              <IconCheck style={{ marginInlineStart: 'auto' }} size={16} />
            )}
          </Group>
        )
      }}
      {...props}
    />
  )
}
