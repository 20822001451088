import { Card, Center, Group, Loader, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { OnlineStatus } from '@/components/deployments/OnlineStatus/OnlineStatus'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetDeviceDetails } from '@/queries/deviceQueries'
import { EdgeClientManager } from './EdgeClientManager'

type DeviceSettingsHandlerProps = {
  deviceId: string
}

export const DeviceSettingsHandler = ({
  deviceId
}: DeviceSettingsHandlerProps) => {
  const { data, isLoading, isError, refetch } = useGetDeviceDetails(deviceId)

  const versionAttribute = data?.attributes.find(
    (attr) => attr.key === 'software_version'
  )

  const currentVersion =
    (versionAttribute?.value as string | undefined) || '0.0.0'

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="devices.settings.error" />
      </ErrorWithReload>
    )
  }

  if (isLoading) {
    return (
      <Center mih={120}>
        <Loader size={24} />
      </Center>
    )
  }

  if (data) {
    return (
      <Card bg="gray.0">
        <Stack gap="xl">
          <Group wrap="nowrap" align="center" gap="xl">
            <Text fw={600} size="md">
              {data.label}
            </Text>

            <OnlineStatus status={data.active ? 'online' : 'offline'} />
          </Group>

          <EdgeClientManager
            deviceId={deviceId}
            currentVersion={currentVersion}
            onUpdated={() => void refetch()}
          />
        </Stack>
      </Card>
    )
  }
}
