import { Group, Loader, Text, ThemeIcon } from '@mantine/core'
import { IconCircleCheckFilled } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type EdgeClientVersionInfoProps = {
  currentVersion: string
  isLoading?: boolean
  hasLatestVersion: boolean
}

export const EdgeClientVersionInfo = ({
  currentVersion,
  isLoading,
  hasLatestVersion
}: EdgeClientVersionInfoProps) => {
  return (
    <Group wrap="nowrap" gap="xs">
      <Text size="sm">{`v${currentVersion}`}</Text>

      {isLoading ? (
        <Loader size={16} />
      ) : hasLatestVersion ? (
        <Group wrap="nowrap" gap={4} align="center">
          <ThemeIcon size="xs" variant="transparent" color="green">
            <IconCircleCheckFilled style={{ width: '100%', height: '100%' }} />
          </ThemeIcon>

          <Text size="sm">
            <FormattedMessage id="devices.client.version.ok" />
          </Text>
        </Group>
      ) : (
        <Text size="sm">
          (<FormattedMessage id="devices.client.pleaseUpdate" />)
        </Text>
      )}
    </Group>
  )
}
