import { ActionIcon, Card, Group, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { MIN_ANNOTATION_POINTS } from '@/config/annotation'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useCreateDatasetVersion,
  useGetDatasetDetails,
  useGetDatasetVersions
} from '@/queries/datasetQueries'
import { useUpdateModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { DatasetVersion, DatasetVersionStatus } from '@/types/dataset'
import { getAnnotationPoints } from '../annotations/helpers/getAnnotationPoints'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { CreateTrainingHandler } from '../trainings/CreateTrainingHandler'
import { TrainingDrawer } from '../trainings/TrainingDrawer/TrainingDrawer'
import { ConfirmDatasetCompletion } from './ConfirmDatasetCompletion/ConfirmDatasetCompletion'
import { CreateDatasetVersionBanner } from './CreateDatasetVersionBanner/CreateDatasetVersionBanner'
import { DatasetToolbar } from './DatasetToolbar/DatasetToolbar'
import { DatasetToolbarSkeleton } from './DatasetToolbar/DatasetToolbarSkeleton'
import { DatasetVersionSelect } from './DatasetVersionSelect/DatasetVersionSelect'
import { DeleteDatasetVersionModal } from './DeleteDatasetVersionModal/DeleteDatasetVersionModal'
import { UpdateDatasetModal } from './UpdateDatasetModal/UpdateDatasetModal'
import { UploadableMediaListHandler } from './UploadableMediaList/UploadableMediaListHandler'
import { useMediaListStatus } from './UploadableMediaList/useMediaListStatus'

export const DatasetDetailsPage = () => {
  const intl = useIntl()
  const [completionConfirmedVersionId, setCompletionConfirmedVersionId] =
    useState('')

  const [
    isUpdateDatasetModalOpened,
    { open: openUpdateDatasetModal, close: closeUpdateDatasetModal }
  ] = useDisclosure(false)

  const [
    isTrainingDrawerOpened,
    { open: openTrainingDrawer, close: closeTrainingDrawer }
  ] = useDisclosure(false)

  const [
    isConfirmCompletionModalOpened,
    { open: openConfirmCompletionModal, close: closeConfirmCompletionModal }
  ] = useDisclosure(false)

  const [
    isDeleteDatasetVersionModalOpened,
    {
      open: openDeleteDatasetVersionModal,
      close: closeDeleteDatasetVersionModal
    }
  ] = useDisclosure(false)

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const datasetId = model?.dataset_version?.dataset || ''
  const datasetVersionId = model?.dataset_version?.id || ''

  const { mutateAsync: updateModel, isPending: isModelUpdatePending } =
    useUpdateModel(model?.id || '')

  const {
    mutateAsync: createDatasetVersion,
    isPending: isCreateVersionPending
  } = useCreateDatasetVersion()

  const {
    data: dataset,
    isLoading,
    refetch: refetchDatasetDetails
  } = useGetDatasetDetails(datasetId, datasetId !== '')

  const { data: versionsData, refetch: refetchVersions } =
    useGetDatasetVersions(datasetId, datasetId !== '')
  const versions = versionsData?.pages?.flatMap((page) => page.results) || []

  const versionSelectOptions =
    versions.map((version) => ({
      value: version.id,
      label: intl
        .formatMessage(
          { id: 'datasets.versionNumber' },
          { number: version.version }
        )
        .toLowerCase(),
      version
    })) || []

  const activeVersion =
    versions.find((version) => version.id === datasetVersionId) ||
    (versions[0] as DatasetVersion | undefined)

  const { annotatedImagesCount, annotatedVideosCount } = useMediaListStatus(
    activeVersion?.id || '',
    activeVersion?.id !== undefined
  )

  const isTrainingButtonDisabled =
    getAnnotationPoints(annotatedImagesCount, annotatedVideosCount) <
    MIN_ANNOTATION_POINTS

  const updateModelDatasetVersion = async (newVersionId: string) => {
    try {
      await updateModel({
        modelId: model?.id || '',
        data: {
          dataset_version_id: newVersionId
        }
      })
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.assign.error' })

      showToast(message, 'error')
    }
  }

  const handleVersionChange = (value: string) => {
    void updateModelDatasetVersion(value)
  }

  const handleCreateNewVersion = async () => {
    try {
      const { id } = await createDatasetVersion({
        datasetId,
        parentVersionId: activeVersion?.id || ''
      })

      void updateModelDatasetVersion(id)
      void refetchVersions()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'datasets.newVersion.create.error' })

      showToast(message, 'error')
    }
  }

  const handleDatasetUpdate = () => {
    closeUpdateDatasetModal()
    void refetchDatasetDetails()
  }

  const handleModelTrainClick = () => {
    if (
      activeVersion?.status !== DatasetVersionStatus.Completed &&
      completionConfirmedVersionId !== activeVersion?.id
    ) {
      openConfirmCompletionModal()
      return
    }

    openTrainingDrawer()
  }

  const handleConfirmCompletion = () => {
    closeConfirmCompletionModal()
    setCompletionConfirmedVersionId(activeVersion?.id || '')
    openTrainingDrawer()
  }

  const handleDatasetVersionDeleted = async () => {
    closeDeleteDatasetVersionModal()
    await refetchDatasetDetails()
    await refetchVersions()
    await updateModelDatasetVersion(versions[0].id)
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <>
          {isLoading && <DatasetToolbarSkeleton />}

          {dataset && (
            <DatasetToolbar
              datasetName={dataset.name}
              datasetType={dataset.dataset_type}
              annotatedImagesCount={annotatedImagesCount}
              annotatedVideosCount={annotatedVideosCount}
              isTrainingButtonDisabled={isTrainingButtonDisabled}
              versionSelector={
                <Group wrap="nowrap" gap="xs">
                  <DatasetVersionSelect
                    value={activeVersion?.id || ''}
                    versions={versionSelectOptions}
                    isPending={isModelUpdatePending}
                    onChange={handleVersionChange}
                  />

                  {versions.length > 1 && (
                    <Tooltip
                      label={<FormattedMessage id="datasets.version.delete" />}
                    >
                      <ActionIcon
                        size="sm"
                        variant="subtle"
                        radius="xl"
                        onClick={openDeleteDatasetVersionModal}
                      >
                        <IconTrash style={{ width: '70%', height: '70%' }} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>
              }
              isAnnotationProgressBarShown
              onSettingsClick={openUpdateDatasetModal}
              onTrainModel={handleModelTrainClick}
            />
          )}
        </>
      }
    >
      {activeVersion && (
        <>
          {activeVersion.status === DatasetVersionStatus.Completed && (
            <Card bg="brand-primary.0" mb="xl">
              <CreateDatasetVersionBanner
                isPending={isCreateVersionPending}
                onCreateVersion={() => void handleCreateNewVersion()}
              />
            </Card>
          )}

          <UploadableMediaListHandler
            key={activeVersion.id}
            version={activeVersion}
          />
        </>
      )}

      <UpdateDatasetModal
        datasetId={dataset?.id || ''}
        opened={isUpdateDatasetModalOpened}
        onClose={closeUpdateDatasetModal}
        onDatasetUpdated={handleDatasetUpdate}
      />

      <TrainingDrawer
        opened={isTrainingDrawerOpened}
        onClose={closeTrainingDrawer}
      >
        {application && model && dataset && (
          <CreateTrainingHandler
            applicationId={application.id}
            model={model}
            onCancel={closeTrainingDrawer}
          />
        )}
      </TrainingDrawer>

      <ConfirmDatasetCompletion
        opened={isConfirmCompletionModalOpened}
        onClose={closeConfirmCompletionModal}
        onConfirm={handleConfirmCompletion}
      />

      <DeleteDatasetVersionModal
        opened={isDeleteDatasetVersionModalOpened}
        datasetVersionId={activeVersion?.id || ''}
        onClose={closeDeleteDatasetVersionModal}
        onVersionDeleted={() => void handleDatasetVersionDeleted()}
      />
    </PageWithFixedToolbar>
  )
}
