import { Badge } from '@mantine/core'
import { useIntl } from 'react-intl'
import { WorkspaceMemberRole } from '@/types/workspace'

type MemberRoleBadgeProps = {
  role: WorkspaceMemberRole
}

const roleMessages = {
  [WorkspaceMemberRole.Owner]: 'workspace.members.role.owner',
  [WorkspaceMemberRole.Admin]: 'workspace.members.role.admin',
  [WorkspaceMemberRole.User]: 'workspace.members.role.user',
  [WorkspaceMemberRole.Viewer]: 'workspace.members.role.viewer',
  [WorkspaceMemberRole.Labeler]: 'workspace.members.role.labeler'
}

export const MemberRoleBadge = ({ role }: MemberRoleBadgeProps) => {
  const intl = useIntl()
  return (
    <Badge radius="sm">{intl.formatMessage({ id: roleMessages[role] })}</Badge>
  )
}
