import { useMutation, useQuery } from '@tanstack/react-query'
import { trainingApi } from '@/api/trainingApi'

export const trainingQueryKeys = {
  all: ['trainings'] as const,
  details: (trainingId: string) =>
    [...trainingQueryKeys.all, 'details', trainingId] as const
}

export const useGetTrainingDetails = (trainingId: string) => {
  return useQuery({
    queryKey: trainingQueryKeys.details(trainingId),
    queryFn: () => trainingApi.getTrainingDetails(trainingId)
  })
}

export const useCreateTraining = () => {
  return useMutation({
    mutationFn: trainingApi.createTraining
  })
}

export const useRetryTraining = () => {
  return useMutation({
    mutationFn: trainingApi.retryTraining
  })
}
