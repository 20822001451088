import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageType } from 'konva/lib/Stage'
import { useMemo, useState } from 'react'
import { getRandomColor } from '@/theme/randomColor'
import { ParameterType } from '@/types/businessLogic'
import { AnnotationValues } from '../AnnotationArea'

export const ROI_NAME_PREFIX = 'area'

type UseDrawRoiProps = {
  roiNumber: number
  onRoiAdd: (annotation: AnnotationValues) => void
}

export const useDrawRoi = ({ roiNumber, onRoiAdd }: UseDrawRoiProps) => {
  const [points, setPoints] = useState<number[][]>([])
  const [position, setPosition] = useState([0, 0])
  const [color, setColor] = useState(getRandomColor())
  const [isMouseOverPoint, setIsMouseOverPoint] = useState(false)
  const [isCompleted, setIsCompleted] = useState(true)

  const flattenedPoints = useMemo(() => {
    return points.concat(position).reduce((a, b) => a.concat(b), [])
  }, [points, position])

  const reset = () => {
    setPoints([])
    setPosition([0, 0])
    setIsMouseOverPoint(false)
    setColor(getRandomColor())
  }

  const exit = () => {
    if (isCompleted) {
      return
    }

    reset()
    setIsCompleted(true)
  }

  const start = () => {
    reset()
    setIsCompleted(false)
  }

  const getMousePos = (stage: StageType | null) => {
    const pointerPosition = stage?.getRelativePointerPosition()

    if (pointerPosition) {
      return [pointerPosition.x, pointerPosition.y]
    }

    return [0, 0]
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isCompleted) {
      return
    }

    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)

    if (isMouseOverPoint && points.length === 4) {
      onRoiAdd({
        type: ParameterType.ROI,
        name: `${ROI_NAME_PREFIX}${roiNumber}`,
        color,
        coordinates: points
      })

      exit()
    }

    if (!isMouseOverPoint && points.length < 4) {
      setPoints([...points, mousePos])
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  const handleMouseOverStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    if (points.length < 4) {
      return
    }

    e.target.scale({ x: 1.5, y: 1.5 })
    setIsMouseOverPoint(true)
  }

  const handleMouseOutStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 1, y: 1 })
    setIsMouseOverPoint(false)
  }

  return {
    color,
    points,
    flattenedPoints,
    isCompleted,
    exit,
    start,
    handleMouseMove,
    handleMouseDown,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint
  }
}
