import { useGetAppMLModels } from '@/queries/modelQueries'
import { MLModelType } from '@/types/model'

export const useModelListStatus = (applicationId: string) => {
  const { data: primaryData, isFetching: isFetchingPrimary } =
    useGetAppMLModels(applicationId, { model_type: MLModelType.Primary })

  const { data: secondaryData, isFetching: isFetchingSecondary } =
    useGetAppMLModels(applicationId, { model_type: MLModelType.Secondary })

  return {
    primaryModelCount: primaryData?.pages[0].count || 0,
    secondaryModelCount: secondaryData?.pages[0].count || 0,
    isStatusLoading: isFetchingPrimary || isFetchingSecondary
  }
}
