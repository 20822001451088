import { Box, Button, Center, LoadingOverlay, Stack, Text } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { getCameraStreamsFromDevice } from '@/components/deployments/deployment-editor/helpers/getCameraStreamsFromDevice'
import {
  useCreateCameraStreams,
  useGetDeviceDetails
} from '@/queries/deviceQueries'
import { showToast } from '@/theme/notifications'
import { CameraSource, Device, ImportCameraData } from '@/types/device'
import { CameraSelectionList } from '../CameraSelectionList/CameraSelectionList'

const buildAlreadyImportedCameraStreams = (
  deviceData: Device | undefined
): ImportCameraData[] => {
  const milestoneCameraStreams = (
    deviceData !== undefined ? getCameraStreamsFromDevice(deviceData) : []
  ).filter((camera) => camera.source === CameraSource.Milestone)

  return milestoneCameraStreams.map((camera) => ({
    id: camera.attributes?.milestone_camera_id || '',
    name: camera.camera_name,
    rtsp: {
      id: camera.attributes?.milestone_stream_ids[0] || '',
      url: '',
      image: null,
      thumbnail: null
    }
  }))
}

type CameraSelectionHandlerProps = {
  deviceId: string
  cameraStreams: ImportCameraData[]
  onImportStart: () => void
  onBack: () => void
}

export const CameraSelectionHandler = ({
  deviceId,
  cameraStreams,
  onImportStart,
  onBack
}: CameraSelectionHandlerProps) => {
  const intl = useIntl()

  const [selectedCameras, setSelectedCameras] = useState<ImportCameraData[]>([])

  const { mutateAsync: createCameras, isPending: isImportPending } =
    useCreateCameraStreams()

  const { data: deviceData, isFetching: isFetchingDeviceData } =
    useGetDeviceDetails(deviceId)

  const alreadyImportedCameraStreams =
    buildAlreadyImportedCameraStreams(deviceData)

  const cameraStreamsToImport = cameraStreams.filter(
    (camera) =>
      !alreadyImportedCameraStreams.some(
        (importedCamera) => importedCamera.id === camera.id
      )
  )

  const handleImportClick = async () => {
    const importData = selectedCameras.map((camera) => ({
      camera_name: camera.name,
      rtsp_url: camera.rtsp.url,
      source: CameraSource.Milestone,
      attributes: {
        milestone_camera_id: camera.id,
        milestone_stream_ids: [camera.rtsp.id]
      }
    }))

    try {
      await createCameras({
        deviceId,
        data: importData
      })

      onImportStart()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'streams.import.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative" mih={120}>
      <LoadingOverlay visible={isFetchingDeviceData || isImportPending} />

      {cameraStreams.length > 0 && (
        <>
          <CameraSelectionList
            selectedCameras={selectedCameras}
            camerasToImport={cameraStreamsToImport}
            alreadyImportedCameras={alreadyImportedCameraStreams}
            onSelectionChange={setSelectedCameras}
          />

          <Center mt="lg">
            <Button
              miw={200}
              disabled={selectedCameras.length === 0}
              leftSection={<IconDownload size={16} />}
              onClick={() => void handleImportClick()}
            >
              <FormattedMessage id="import" />
            </Button>
          </Center>
        </>
      )}

      {cameraStreams.length === 0 && (
        <Stack align="center" gap="xl">
          <Text size="sm" ta="center">
            <FormattedMessage id="streams.import.noCameraStreams" />
          </Text>

          <Box>
            <Button variant="outline" miw={120} onClick={onBack}>
              <FormattedMessage id="goBack" />
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  )
}
