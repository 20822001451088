import { Event } from '@/types/event'

export const extractRoiNames = (event: Event) => {
  if (event.output.stream.business_logics.length === 0) {
    return ''
  }

  if ('event_json_version' in event) {
    return Object.values(event.output.stream.business_logics[0])
      .flatMap((logic) => Object.keys(logic))
      .join(', ')
  }

  // Extract roi from legacy event
  return event.output.stream.business_logics[0].parameters
    .map((param) => param.name)
    .join(', ')
}
