export type Application = {
  id: string
  name: string
  application_type: ApplicationType
  application_type_display: string
  created_at: string
  created_by: string
  updated_at: string
}

export enum ApplicationType {
  SingleStage = 1,
  MultiStage = 2
}
