import { Badge, Group, Select, SelectProps, rem } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { useIntl } from 'react-intl'

export type LabelOption = {
  value: string
  label: string
  color: string
}

export const ObjectSelect = (props: SelectProps) => {
  const intl = useIntl()

  return (
    <Select
      size="xs"
      allowDeselect={false}
      placeholder={intl.formatMessage({
        id: 'logic.rules.object.select'
      })}
      comboboxProps={{
        width: 300,
        position: 'bottom-start'
      }}
      styles={{
        input: {
          fontSize: rem(11),
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      }}
      renderOption={({ option, checked }) => {
        const { label, color } = option as LabelOption

        return (
          <Group flex="1" gap="xs">
            <Badge color={color} size="sm">
              {label}
            </Badge>

            {checked && (
              <IconCheck style={{ marginInlineStart: 'auto' }} size={16} />
            )}
          </Group>
        )
      }}
      {...props}
    />
  )
}
