import { FormErrors } from '@mantine/form'

export const getFieldErrors = (
  formErrors: FormErrors,
  fieldName: string,
  index: number
) => {
  return Object.entries(formErrors).reduce((errors, [key, value]) => {
    if (key.startsWith(`${fieldName}.${index}.`)) {
      const newKey = key.replace(`${fieldName}.${index}.`, '')
      errors[newKey] = value
    }
    return errors
  }, {} as FormErrors)
}
