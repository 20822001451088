import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  Text,
  Tooltip
} from '@mantine/core'
import { IconBolt, IconSettings } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { AnnotationProgressBar } from '@/components/annotations/AnnotationProgressBar/AnnotationProgressBar'
import { DatasetType } from '@/types/dataset'
import { DatasetTypeChip } from '../DatasetTypeChip/DatasetTypeChip'

type DatasetToolbarProps = {
  datasetName: string
  datasetType: DatasetType
  versionSelector?: ReactNode
  annotatedImagesCount: number
  annotatedVideosCount: number
  isAnnotationProgressBarShown: boolean
  isTrainingButtonDisabled: boolean
  onSettingsClick: () => void
  onTrainModel: () => void
}

export const DatasetToolbar = ({
  datasetName,
  datasetType,
  versionSelector,
  annotatedImagesCount,
  annotatedVideosCount,
  isAnnotationProgressBarShown,
  isTrainingButtonDisabled,
  onSettingsClick,
  onTrainModel
}: DatasetToolbarProps) => {
  return (
    <Box py="sm">
      <Grid align="center">
        <Grid.Col span="auto">
          <Group align="center" gap="lg">
            <Group wrap="nowrap" gap={6} align="start">
              <Tooltip label={<FormattedMessage id="datasets.settings" />}>
                <ActionIcon
                  variant="subtle"
                  size={20}
                  radius="xl"
                  onClick={onSettingsClick}
                >
                  <IconSettings style={{ width: '80%', height: '80%' }} />
                </ActionIcon>
              </Tooltip>

              <Text size="md" fw={600} maw={300} truncate>
                {datasetName}
              </Text>

              <DatasetTypeChip datasetType={datasetType} />
            </Group>

            <Box miw={180}>{versionSelector}</Box>
          </Group>
        </Grid.Col>

        <Grid.Col span="content">
          <Group>
            {isAnnotationProgressBarShown && (
              <Box w={280}>
                <AnnotationProgressBar
                  annotatedImagesCount={annotatedImagesCount}
                  annotatedVideosCount={annotatedVideosCount}
                />
              </Box>
            )}

            <Button
              leftSection={<IconBolt size={16} />}
              miw={180}
              color="green"
              disabled={isTrainingButtonDisabled}
              onClick={onTrainModel}
            >
              <FormattedMessage id="models.trainModel" />
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
