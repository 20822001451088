import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconLine, IconVector } from '@tabler/icons-react'
import { ParameterType } from '@/types/businessLogic'

type ParameterHeaderProps = {
  parameterType: ParameterType
  name: string
  color: string
}

export const ParameterHeader = ({
  parameterType,
  name,
  color
}: ParameterHeaderProps) => (
  <Group align="center" gap="xs">
    <ThemeIcon color={color} variant="light" size="sm">
      {parameterType === ParameterType.LineCrossing ? (
        <IconLine style={{ width: '90%', height: '90%' }} />
      ) : (
        <IconVector style={{ width: '90%', height: '90%' }} />
      )}
    </ThemeIcon>

    <Text size="xs" fw={600} c={color}>
      {name}
    </Text>
  </Group>
)
