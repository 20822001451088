import { Modal, Tabs, rem } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type AddStreamModalProps = {
  opened: boolean
  addStreamTab: ReactNode
  importStreamTab: ReactNode
  onClose: () => void
}

export const AddStreamModal = ({
  opened,
  addStreamTab,
  importStreamTab,
  onClose
}: AddStreamModalProps) => {
  return (
    <Modal
      opened={opened}
      size="lg"
      title={<FormattedMessage id="streams.addCameraStream" />}
      onClose={onClose}
    >
      <Tabs
        defaultValue="add_stream"
        variant="outline"
        styles={{
          panel: {
            marginTop: rem(24)
          }
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="add_stream">
            <FormattedMessage id="streams.new" />
          </Tabs.Tab>

          <Tabs.Tab value="import_stream">
            <FormattedMessage id="streams.import" />
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="add_stream">{addStreamTab}</Tabs.Panel>
        <Tabs.Panel value="import_stream">{importStreamTab}</Tabs.Panel>
      </Tabs>
    </Modal>
  )
}
