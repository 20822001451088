import { MLModel, MLModelType } from '@/types/model'
import { PaginatedResponse } from '@/types/pagination'
import { vpApi } from './vpApi'

type GetModelsParams = {
  pageUrl: string
}

type MLModelsResponse = PaginatedResponse<MLModel[]>

const getMLModels = async ({ pageUrl }: GetModelsParams) => {
  const res = await vpApi.get<MLModelsResponse>(pageUrl)
  return res.data
}

const getModelDetails = async (modelId: string) => {
  const res = await vpApi.get<MLModel>(`/v1/applications/models/${modelId}/`)
  return res.data
}

type CreateModelData = {
  application_id: string
  name: string
  model_type: MLModelType
}

const createModel = (data: CreateModelData) => {
  return vpApi.post<MLModel>(`/v1/applications/models/`, data)
}

type UpdateModelParams = {
  modelId: string
  data: {
    base_model_id?: string
    name?: string
    dataset_version_id?: string
  }
}

const updateModel = ({ modelId, data }: UpdateModelParams) => {
  return vpApi.patch(`/v1/applications/models/${modelId}/`, data)
}

const deleteModel = (modelId: string) => {
  return vpApi.delete(`/v1/applications/models/${modelId}/`)
}

export const modelAPI = {
  getMLModels,
  getModelDetails,
  createModel,
  updateModel,
  deleteModel
}
