export const VP_TERMS_AND_CONDITIONS_URL = import.meta.env
  .VITE_VP_TERMS_AND_CONDITIONS_URL

export const VP_PRIVACY_POLICY_URL = import.meta.env.VITE_VP_PRIVACY_POLICY_URL

export const VP_CONTACT_EMAIL = import.meta.env.VITE_VP_CONTACT_EMAIL
export const VP_CONTACT_PHONE = import.meta.env.VITE_VP_CONTACT_PHONE

export const VP_API_BASE_URL = import.meta.env.VITE_VP_API_BASE_URL

export const VP_PRICING_PAGE = import.meta.env.VITE_VP_PRICING_PAGE

export const REACT_QUERY_DEVTOOLS_ENABLED = import.meta.env
  .VITE_REACT_QUERY_DEVTOOLS_ENABLED

export const SCENARIO_SIMULATOR_QUERY_PARAM = 'scenario'

export const DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP = import.meta.env
  .VITE_DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP

export const CHATBOT_CHATFLOWID = import.meta.env.VITE_CHATBOT_CHATFLOWID
export const CHATBOT_APIHOST = import.meta.env.VITE_CHATBOT_APIHOST

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED

export const ENV_NAME = import.meta.env.VITE_ENV_NAME

export const EDGE_CLIENT_VERSION_FILE = import.meta.env
  .VITE_EDGE_CLIENT_VERSION_FILE

export const DISABLE_WORKSPACES = import.meta.env.VITE_DISABLE_WORKSPACES
