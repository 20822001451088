export type LibraryLogic = {
  id: string
  name: string
  logic_type: LibraryLogicType
  logic_type_display: string
  created_at: string
  updated_at: string
  parameters: LibraryLogicParameter[]
}

export enum LibraryLogicType {
  ObjectDetection = 1,
  ObjectCount = 2
}

type LibraryLogicParameter = {
  id: string
  name: string
  parameter_type: ParameterType
  parameter_type_display: string
  created_at: string
  updated_at: string
}

export enum ParameterType {
  ROI = 1,
  LineCrossing = 2
}

export type BusinessLogic = {
  id: string
  name?: string
  logic_type?: LibraryLogicType
  parameters: BusinessLogicParameter[]
}

export type BusinessLogicParameter = {
  parameter_type: ParameterType
  coordinates: string
  name: string
  event: EventConfig
  rules: BusinessLogicRule[]
}

type EventConfig = {
  alert: boolean
  upload: boolean
}

export type BusinessLogicRule = {
  operator: BusinessLogicRuleOperator
  label_groups: LabelGroup[]
}

export enum BusinessLogicRuleOperator {
  AND = 'AND',
  OR = 'OR'
}

export type LabelGroup = {
  type: LabelGroupType
  label_id: string
  threshold: number
  sub_labels?: SubLabel[]
}

export enum LabelGroupType {
  Single = 'single',
  Group = 'group'
}

type SubLabel = {
  operator: SubLabelOperator
  label_ids: string[]
}

export enum SubLabelOperator {
  With = 'WITH',
  Without = 'WITHOUT'
}

// Below, normalized types used in the FE
export type NormalizedStreamBusinessLogic = {
  logic_id: string
  logic_type: LibraryLogicType
  logic_name: string
  camera_id: string
  camera_name: string
  camera_image: string
  live_inference: boolean
  device_id: string
  parameters: NormalizedBusinessLogicParameter[]
}

export type NormalizedBusinessLogicParameter = {
  type: ParameterType
  name: string
  color: string
  coordinates: number[][]
  rules: NormalizedBusinessLogicRule[]
  notification: boolean
  video_upload: boolean
}

export type NormalizedBusinessLogicRule = {
  operator: BusinessLogicRuleOperator
  label_groups: NormalizedLabelGroup[]
}

export type NormalizedLabelGroup = {
  uuid: string
  type: LabelGroupType
  label_id: string
  sub_label_ids: string[]
  sub_label_operator: SubLabelOperator
  threshold: number
}
