import { Button, Card, CardSection, Group } from '@mantine/core'
import { IconLine, IconVector } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type AnnotationAreaContainerProps = {
  children: ReactNode
  showRoi: boolean
  showLineCrossing: boolean
  isRoiInProgress?: boolean
  isLineCrossingInProgress?: boolean
  onAddLineCrossing: () => void
  onAddRoi: () => void
}

export const AnnotationAreaContainer = ({
  children,
  showRoi,
  showLineCrossing,
  isRoiInProgress,
  isLineCrossingInProgress,
  onAddLineCrossing,
  onAddRoi
}: AnnotationAreaContainerProps) => {
  return (
    <Card radius={0} withBorder>
      <Card.Section py="sm" withBorder inheritPadding>
        <Group>
          {showLineCrossing && (
            <Button
              leftSection={<IconLine size={16} />}
              size="xs"
              radius="lg"
              miw={120}
              disabled={isLineCrossingInProgress}
              onClick={onAddLineCrossing}
            >
              <FormattedMessage id="logic.lineCrossing.add" />
            </Button>
          )}

          {showRoi && (
            <Button
              leftSection={<IconVector size={16} />}
              size="xs"
              radius="lg"
              miw={120}
              disabled={isRoiInProgress}
              onClick={onAddRoi}
            >
              <FormattedMessage id="logic.roi.add" />
            </Button>
          )}
        </Group>
      </Card.Section>

      <CardSection>{children}</CardSection>
    </Card>
  )
}
