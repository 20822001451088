import {
  Button,
  Input,
  SegmentedControl,
  Stack,
  TextInput
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useIntl } from 'react-intl'
import {
  Workspace,
  WorkspaceCreateUpdateParam,
  WorkspaceType
} from '@/types/workspace'

const alphanumericRegex = /^[a-zA-Z0-9\s]*$/

type WorkspaceFormParam = {
  workspace?: Workspace
  onSubmit: (formValues: WorkspaceCreateUpdateParam) => void
  submitLabel: string
}

export const WorkspaceForm = ({
  workspace,
  submitLabel,
  onSubmit
}: WorkspaceFormParam) => {
  const intl = useIntl()
  const form = useForm({
    initialValues: {
      workspace_type: (workspace?.workspace_type || '') as WorkspaceType,
      name: workspace?.name || ''
    },

    validate: {
      name: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({ id: 'workspace.create.nameRequired' })
        }

        if (!alphanumericRegex.test(value)) {
          return intl.formatMessage({ id: 'workspace.create.invalidName' })
        }
      },
      workspace_type: isNotEmpty(
        intl.formatMessage({
          id: 'workspace.create.workspaceTypeRequired'
        })
      )
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit(onSubmit)}>
      <Stack gap="sm">
        <TextInput
          label={intl.formatMessage({ id: 'workspace.create.name' })}
          {...form.getInputProps('name')}
        />

        <Input.Wrapper
          label={intl.formatMessage({ id: 'workspace.create.type' })}
          error={form.errors.workspace_type}
        >
          <SegmentedControl
            {...form.getInputProps('workspace_type')}
            data={[
              {
                label: intl.formatMessage({
                  id: 'workspace.type.organization'
                }),
                value: WorkspaceType.Organization
              },
              {
                label: intl.formatMessage({ id: 'workspace.type.personal' }),
                value: WorkspaceType.Personal
              }
            ]}
            fullWidth
          />
        </Input.Wrapper>

        <Button type="submit" mt="sm" fullWidth>
          {submitLabel}
        </Button>
      </Stack>
    </form>
  )
}
