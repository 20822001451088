import { Drawer } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type DeviceSettingsOverlayProps = {
  opened: boolean
  onClose: () => void
  children: ReactNode
}

export const DeviceSettingsOverlay = ({
  opened,
  onClose,
  children
}: DeviceSettingsOverlayProps) => {
  return (
    <Drawer
      opened={opened}
      title={<FormattedMessage id="devices.settings" />}
      position="right"
      size="xl"
      onClose={onClose}
    >
      {children}
    </Drawer>
  )
}
