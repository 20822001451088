import { Box, LoadingOverlay } from '@mantine/core'
import { ReactNode, createContext, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetModelDetails } from '@/queries/modelQueries'
import { MLModel } from '@/types/model'

type ContextProps = {
  model: MLModel | null
  isFetching: boolean
}

const initialValues = {
  model: null,
  isFetching: false
}

const ModelContext = createContext<ContextProps>(initialValues)

type ModelProviderProps = {
  children: ReactNode
}

export const ModelProvider = ({ children }: ModelProviderProps) => {
  const { modelId } = useParams()

  const {
    data: model,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useGetModelDetails(modelId || '')

  const handleOnReload = () => {
    void refetch()
  }

  if (isLoading) {
    return (
      <Box pos="relative" mih={200}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <ModelContext.Provider value={{ model: model || null, isFetching }}>
      {model && children}
    </ModelContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useModelContext = () => {
  return useContext(ModelContext)
}
