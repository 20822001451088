import { Box, Button, Group, Stack, Text, ThemeIcon } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconCrop } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelMultiSelect } from '@/components/labels/LabelMultiSelect/LabelMultiSelect'
import { useGetDatasetVersions, useGetDatasets } from '@/queries/datasetQueries'
import { DatasetLabelingType, DatasetType } from '@/types/dataset'
import { DatasetSelect } from './DatasetSelect'
import { DatasetVersionSelect } from './DatasetVersionSelect'

export type SubmitValues = {
  sourceDatasetId: string
  sourceDatasetVersionId: string
  label_ids: string[]
}

type FormValues = {
  sourceDatasetId: string | null
  sourceDatasetVersionId: string | null
  label_ids: string[]
}

type ImageCropHandlerProps = {
  onSkip: () => void
  onSubmit: (values: SubmitValues) => void
}

export const ImageCropForm = ({ onSkip, onSubmit }: ImageCropHandlerProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      sourceDatasetId: null,
      sourceDatasetVersionId: null,
      label_ids: []
    },

    validate: {
      sourceDatasetId: isNotEmpty(),
      sourceDatasetVersionId: isNotEmpty(),
      label_ids: isNotEmpty(
        intl.formatMessage({
          id: 'annotation.classes.required'
        })
      )
    }
  })

  const { sourceDatasetId, label_ids } = form.getValues()

  const { data: datasetList, isFetching: isFetchingDatasetList } =
    useGetDatasets({
      is_library: false,
      ordering: 'created_at',
      limit: 100,
      dataset_type: DatasetType.Primary,
      labeling_type: DatasetLabelingType.ObjectDetection
    })

  const { data: datasetVersionList, isFetching: isFetchingVersionList } =
    useGetDatasetVersions(sourceDatasetId || '', sourceDatasetId !== null)

  const datasets = datasetList?.pages?.flatMap((page) => page.results) || []

  const versions =
    datasetVersionList?.pages?.flatMap((page) => page.results) || []

  const selectedDataset = datasets.find(
    (dataset) => dataset.id === sourceDatasetId
  )

  const labelOptions =
    selectedDataset?.labels.map((label) => ({
      labelId: label.id,
      name: label.name,
      color: label.color
    })) || []

  const handleSubmit = (values: FormValues) => {
    onSubmit(values as SubmitValues)
  }

  const handleDatasetChange = (datasetId: string) => {
    form.setFieldValue('sourceDatasetId', datasetId)
    form.setFieldValue('sourceDatasetVersionId', null)
    form.setFieldValue('label_ids', [])
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Box mih={420}>
        <Group wrap="nowrap" align="center" mb="xl">
          <ThemeIcon variant="light" size="xl" radius="xl">
            <IconCrop style={{ width: '80%', height: '80%' }} />
          </ThemeIcon>

          <Text size="sm">
            <FormattedMessage id="datasets.crop.info" />
          </Text>
        </Group>

        <Group wrap="nowrap" gap="xs" grow>
          <DatasetSelect
            datasets={datasets}
            disabled={isFetchingDatasetList}
            {...form.getInputProps('sourceDatasetId')}
            onChange={(val) => handleDatasetChange(val as string)}
          />

          <DatasetVersionSelect
            versions={versions}
            disabled={isFetchingVersionList}
            {...form.getInputProps('sourceDatasetVersionId')}
          />
        </Group>

        {sourceDatasetId && (
          <Stack mt={48} gap="xs">
            <Text size="sm">
              <FormattedMessage id="datasets.crop.selectClasses" />
            </Text>

            <LabelMultiSelect
              values={label_ids}
              options={labelOptions}
              error={form.errors.label_ids}
              onChange={(values) => form.setFieldValue('label_ids', values)}
            />
          </Stack>
        )}
      </Box>

      <Group justify="end" mt="xl">
        <Button miw={160} variant="default" onClick={onSkip}>
          <FormattedMessage id="datasets.crop.skip" />
        </Button>

        <Button type="submit" miw={160}>
          <FormattedMessage id="datasets.crop" />
        </Button>
      </Group>
    </form>
  )
}
