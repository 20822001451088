import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { ErrorBanner } from '@/components/ui-shared/ErrorBanner/ErrorBanner'
import { useCreateModel } from '@/queries/modelQueries'
import { buildModelLink } from '@/router/paths'
import { MLModelType } from '@/types/model'
import { CreateModelForm, FormValues } from '../CreateModelForm/CreateModelForm'

type CreateModelErrorProps = {
  error: ApiError
}

const CreateModelError = ({ error }: CreateModelErrorProps) => {
  const { errorMessage } = error

  if (errorMessage) {
    return <ErrorBanner>{errorMessage}</ErrorBanner>
  }

  return (
    <ErrorBanner>
      <FormattedMessage id="models.create.apiError" />
    </ErrorBanner>
  )
}

type CreateModelModalProps = {
  appId: string
  modelType: MLModelType
  opened: boolean
  onClose: () => void
}

export const CreateModelModal = ({
  appId,
  modelType,
  opened,
  onClose
}: CreateModelModalProps) => {
  const navigate = useNavigate()

  const {
    mutateAsync: createModel,
    isPending,
    isError,
    error,
    reset
  } = useCreateModel()

  const handleCreateModel = async ({ name }: FormValues) => {
    const { data: model } = await createModel({
      application_id: appId,
      name,
      model_type: modelType
    })

    navigate(buildModelLink(appId, model.id))
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="models.createModel" />}
      onClose={handleClose}
    >
      <Box pos="relative">
        {isError && (
          <Box mb="lg">
            <CreateModelError error={getApiError(error)} />
          </Box>
        )}

        <LoadingOverlay visible={isPending} />

        <CreateModelForm
          onCancel={handleClose}
          onSubmit={(values) => void handleCreateModel(values)}
        />
      </Box>
    </Modal>
  )
}
