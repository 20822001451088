import { Modal } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type ImageCropModalProps = {
  opened: boolean
  children: ReactNode
  onClose: () => void
}
export const ImageCropModal = ({
  opened,
  children,
  onClose
}: ImageCropModalProps) => {
  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="datasets.crop" />}
      opened={opened}
      onClose={onClose}
    >
      {children}
    </Modal>
  )
}
